import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppConstants} from "../../../shared/constants/app.constants";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../../services/company.service";
import {ApplicationService} from "../../../services/application.service";
import {ProfileService} from "../../../services/profile.service";
import {ParameterService} from "../../../services/parameter.service";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {forkJoin, of} from "rxjs";

declare const hideMessage: any;
declare const showMessage: any;

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {
  userInfo = JSON.parse(sessionStorage.getItem(AppConstants.Session.USER_INFO)!);
  columns = [] as any[];
  columnsAditionalAttributes = [] as any[];
  loading: boolean = false;
  userId: number = 0;

  listApplications = [] as any[];
  listProfiles = [] as any[];
  listAreas = [] as any[];
  listAgencies = [] as any[];
  listChannels = [] as any[];
  listSubChannels = [] as any[];
  listSubChannelsCtrl = [] as any[];
  listCountries = [] as any[];
  listCompanyTypes = [] as any[];
  listUserTypes = [] as any[];
  listDocumentTypes = [] as any[];
  listCompanies = [] as any[];
  listCompaniesAll = [] as any[];

  userForm: FormGroup;
  isUserTypeInter: boolean = false;

  agencyCode: string = 'PE01254878';
  agencyName: string = 'Plaza Norte';
  usersessionId: string = 'jreyes';
  channelName: string = 'otros';

  @ViewChild('indexTemplate') indexTemplate: TemplateRef<any>
  @ViewChild('stateTemplate') stateTemplate: TemplateRef<any>
  @ViewChild('indexAditionalAttributeTemplate') indexAditionalAttributeTemplate: TemplateRef<any>
  @ViewChild('estateAditionalAttributeTemplate') estateAditionalAttributeTemplate: TemplateRef<any>

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private applicationService: ApplicationService,
    private profileService: ProfileService,
    private parameterService: ParameterService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getParametersData();
    this.createColumsTable();
  }

  get perfiles(): any {
    return this.userForm?.controls["profiles"]?.value || [];
  }

  get additionalAttributes(): any {
    return this.userForm?.controls["additionalAttributes"]?.value || [];
  }

  getParametersData() {
    this.loading = true;
    this.userId = +this.activatedRoute.snapshot.paramMap.get('id')!;

    forkJoin({
      agencias: this.parameterService.getMasterDataAgency(),
      areas: this.parameterService.getMasterDataArea(),
      canales: this.parameterService.getMasterDataChannel(),
      subCanales: this.parameterService.getMasterDataSubChannel(),
      paises: this.parameterService.getParameterData(AppConstants.ParametryCode.COUNTRY),
      tipoEmpresa: this.parameterService.getParameterData(AppConstants.ParametryCode.COMPANY_TYPE),
      tipoUsuario: this.parameterService.getParameterData(AppConstants.ParametryCode.USER_TYPE),
      tipoDocumento: this.parameterService.getParameterData(AppConstants.ParametryCode.DOCUMENT_TYPE),
      applicaciones: this.applicationService.getApplications(),
      empresas: this.companyService.getCompanies(),
      usuario: this.userId == 0 ? of(null) : this.userService.getUserRol(this.userId)
    }).subscribe(req => {
      this.listAgencies = req.agencias;
      this.listAreas = req.areas;
      this.listChannels = req.canales;
      this.listSubChannels = req.subCanales;
      this.listSubChannelsCtrl = req.subCanales;
      this.listCountries = req.paises;
      this.listCompanyTypes = req.tipoEmpresa;
      this.listUserTypes = req.tipoUsuario;
      this.listDocumentTypes = req.tipoDocumento;
      this.listApplications = req.applicaciones;
      this.listCompaniesAll = req.empresas;
      if (this.userId != 0) {
        for (const x of req.usuario.profiles) {
          x.application = this.listApplications.filter((z: any) => z.id == x.applicationId)[0].name;
        }

        this.setFieldsUser(req.usuario);
        this.userForm.get("password")?.clearValidators();
        (this.userForm.get("password") as FormControl).updateValueAndValidity()
      } else {
        if (this.userInfo.userTypeId == AppConstants.UserType.External) {
          this.userForm.patchValue({
            userTypeId: this.userInfo.userTypeId,
            countryId: this.userInfo.countryId,
            companyId: this.userInfo.companyId
          });
          this.userForm.controls['userTypeId'].disable();
          this.userForm.controls['companyId'].disable();
          this.changePais();
          this.changeUserType();
        }
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      id: [],
      userTypeId: [{value: '', disabled: false}, []],
      username: ['', []],
      password: ['', []],
      countryId: ['', []],
      companyId: ['', []],
      firstName: ['', []],
      lastName: ['', []],
      documentTypeId: ['', []],
      documentNumber: ['', []],
      email: ['', []],
      bankCode: ['', []],
      state: ['', []],
      additionalAttributes: [''],
      profiles: [''],
    });
  }

  setFieldsUser(req: any = 0) {
    this.userForm.disable();
    this.userForm.patchValue(req);
    this.changePais();
    this.changeUserType();
  }

  changePais(): void {
    const data = this.userForm.getRawValue();
    if (data && data.countryId) {
      this.listCompanies = this.listCompaniesAll.filter(x => x.countryId == data.countryId);
    }
  }

  changeUserType() {
    this.isUserTypeInter = this.userForm.value['userTypeId'] == AppConstants.UserType.Internal;
  }

  createColumsTable(): void {
    setTimeout(() => {
      this.columns = [
        {cellTemplate: this.indexTemplate, name: 'Id', width: 40, draggable: false, resizeable: false},
        {prop: 'application', name: 'Aplicación', width: 80, draggable: false, resizeable: false},
        {prop: 'profile', name: 'Perfil', width: 150, draggable: false, resizeable: false},
        {cellTemplate: this.stateTemplate, name: 'Estado', width: 50, draggable: false, resizeable: false},
      ];

      this.columnsAditionalAttributes = [
        {
          cellTemplate: this.indexAditionalAttributeTemplate,
          name: 'Id',
          width: 40,
          draggable: false,
          resizeable: false
        },
        {prop: 'key', name: 'Key', width: 80, draggable: false, resizeable: false},
        {prop: 'value', name: 'Valor', width: 150, draggable: false, resizeable: false},
        {
          cellTemplate: this.estateAditionalAttributeTemplate,
          prop: 'state', name: 'Estado', width: 50, draggable: false, resizeable: false
        }
      ];

    }, 0);
  }

  back() {
    this.location.back();
  }


  showMessage() {
    showMessage();
  }

  hideMessage() {
    hideMessage();
  }
}
