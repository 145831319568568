export const AppConstants = {
  System: 1,
  Session: {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TOKEN_TYPE: 'TOKEN_TYPE',
    USERID: 'USERID',
    TIMESECONDS: 'TIMESECONDS',
    CHANGEPASSWORD: 'CHANGEPASSWORD',
    EXPIREDPASSWORD: 'EXPIREDPASSWORD',
    AUDIT: 'AUDIT',
    MENU: 'MENU',
    MENU_ACTIONS: 'MENU_ACTIONS',
    USER_INFO: 'USER_INFO'
  },
  TitleModal: {
    GET_PARAMETER: 'Consulta Parametro',
    ADD_PARAMETER: 'Agregar Parametro',
    UPDATE_PARAMETER: 'Editar Parametro',
    GET_APPLICATION: 'Consulta Aplicación',
    ADD_APPLICATION: 'Agregar Aplicación',
    UPDATE_APPLICATION: 'Editar Aplicación',
    GET_MENU: 'Consulta Menú',
    ADD_MENU: 'Agregar Menú',
    UPDATE_MENU: 'Editar Menú',
    GET_USER: 'Consulta Usuario',
    ADD_USER: 'Agregar Usuario',
    UPDATE_USER: 'Editar Usuario',
    GET_COMPANY: 'Consulta Empresa',
    ADD_COMPANY: 'Agregar Empresa',
    UPDATE_COMPANY: 'Editar Empresa',
    GET_PROFILE: 'Consulta Perfil',
    ADD_PROFILE: 'Agregar Perfil',
    UPDATE_PROFILE: 'Editar Perfil',
    GET_ACTION: 'Consulta Acción',
    ADD_ACTION: 'Agregar Acción',
    UPDATE_ACTION: 'Editar Acción',
    LOGIN_TITLE: 'Inicio Sesión',
    RESET_PASSWORD: 'Restablecer Contraseña',
    VALIDATE_USER: 'Validar usuario en AD',
    CHANGE_STATE: 'Cambio de estado'
  },
  Actions: {
    GET: 'Consultar',
    ADD: 'Agregar',
    UPDATE: 'Editar',
  },
  ParametryCode: {
    COUNTRY: 1,
    ACCESS_TYPE: 2,
    DOCUMENT_TYPE: 3,
    COMPANY_TYPE: 4,
    USER_TYPE: 5,
  },
  Messages: {
    SUCCESS_PASSWORD: 'Contraseña modificada',
    SUCCESS_DATA: 'Datos modificados',
    SUCCESS_VALIDATE: 'Datos validados',
    DIFFERENT_PASSWORD: 'Las contraseñas deben coincidir',
    CHANGED_PASSWORD: 'Contraseña modificada',
    PARAMETER_EMPTY: 'Debe llenar los campos',
    MENU_EMPTY: 'Debe seleccionar una aplicación',
    MENU_ITEM: 'Debe seleccionar al menos un item',
    NO_DATA: 'No hay datos',
    NOT_ALLOWED: 'Acción no permitida',
    ERROR_OCURRED: 'Ocurrio un error',
    DIFFERENT_ROL: 'No puede agregar dos roles a una misma aplicación',
    ACTIVE_DIRECTORY: 'Necesita validar en Active Directory',
    LOGIN_ERROR: 'Usuario Sin Autorización',
    SUCCESS_REGISTER: 'Registrado correctamente',
    SUCCESS_EDIT: "Modificado correctamente",
    RECAPTCHA_ERROR: 'Error en validación reCaptcha',
    EMAIL_INVALID: 'El Email tiene un formato invalido',
    DOCUMENT_NUMBER_INVALID: 'El Numero de documento ingresado tiene un formato invalido',
  },
  MenuActions: {
    GET_PARAMETER: 1,
    ADD_PARAMETER: 2,
    UPDATE_PARAMETER: 3,
    GET_DETAIL_PARAMETER: 4,
    ADD_DETAIL_PARAMETER: 5,
    UPDATE_DETAIL_PARAMETER: 6,
    GET_APPLICATION: 7,
    ADD_APPLICATION: 8,
    UPDATE_APPLICATION: 9,
    GET_MENU: 30,
    ADD_MENU: 10,
    UPDATE_MENU: 11,
    GET_PROFILE: 12,
    ADD_PROFILE: 13,
    UPDATE_PROFILE: 14,
    GET_ACTION: 34,
    ADD_ACTION: 35,
    GET_USER: 15,
    ADD_USER: 16,
    UPDATE_USER: 17,
    RESET_PASSWORD: 18,
    GET_COMPANY: 31,
    ADD_COMPANY: 32,
    UPDATE_COMPANY: 33,
    ACTIVATE_USER: 36,
    DEACTIVATE_USER: 37,
    VIEW_DETAIL_USER: 38
  },
  UserType: {
    Internal: 1,
    External: 2
  },
  Application: {
    Security: 1
  }
}




