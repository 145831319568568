import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { SharedModule } from './shared/modules/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastaModule } from 'ngx-toasta';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree'
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AppComponent } from './app.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { ApplicationComponent } from './components/application/application.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MenuComponent } from './components/menu/menu.component';
import { UserComponent } from './components/user/user.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ParametryComponent } from './components/parametry/parametry.component';
import { CompanyComponent } from './components/company/company.component';
import { LoginComponent } from './components/login/login.component';

import { ParametryDetailComponent } from './components/parametry/parametry-detail/parametry-detail.component';
import { UserAddComponent } from './components/user/user-add/user-add.component';
import { ProfileActionComponent } from './components/profile/profile-action/profile-action.component';

import { AlertService } from './shared/services/alert.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UserInfoComponent } from './shared/components/user-info/user-info.component';
import {ErrorsInterceptor} from './shared/services/errors.interceptor';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';
import { ParametryDetailAddComponent } from './components/parametry/parametry-detail-add/parametry-detail-add.component';
import { ProfileReportComponent } from './components/profile-report/profile-report.component';
import { NumberOnlyDirective } from './shared/directive/number-only.directive';
import { LetterOnlyDirective } from './shared/directive/letter-only.directive';
import {DocumentNumberDirective} from "./shared/directive/document-number.directive";
import { UserViewComponent } from './components/user/user-view/user-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    HeaderComponent,
    HomeComponent,
    ApplicationComponent,
    ProfileComponent,
    MenuComponent,
    UserComponent,
    NotFoundComponent,
    ParametryComponent,
    CompanyComponent,
    ParametryDetailComponent,
    UserAddComponent,
    ProfileActionComponent,
    UserInfoComponent,
    ParametryDetailAddComponent,
    ProfileReportComponent,
    NumberOnlyDirective,
    LetterOnlyDirective,
    DocumentNumberDirective,
    UserViewComponent
  ],
  imports: [
    RecaptchaV3Module,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    ToastaModule.forRoot(),
    // TreeviewModule.forRoot(),
    FormsModule,
    // NgxDatatableModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AlertService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
