<div class="modal-header">
  <h5 class="modal-title pull-left">{{titleModal}}</h5>
</div>
<div class="modal-body text-left">
  <form class="form-cliente" autocomplete="offs" [formGroup]="parameterForm">
    <div class="row justify-content-center">
      <div class="form-group col-sm-12">
        <label class="subtitle-modal">Llave</label>
        <input type="text" class="form-control" formControlName="key"/>
      </div>
      <div class="form-group col-sm-12">
        <label class="subtitle-modal">Código</label>
        <input type="text" class="form-control" formControlName="code"/>
      </div>
      <div class="form-group col-sm-12">
        <label class="subtitle-modal">Valor</label>
        <input type="text" class="form-control" formControlName="value"/>
      </div>
      <div class="form-group col-sm-12">
        <label class="subtitle-modal">Estado</label>
        <select class="form-control input-form" formControlName="state">
          <option [value]="true">Activo</option>
          <option [value]="false">Inactivo</option>
        </select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-evenly">
  <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
          title="Cancelar" (click)="closeModal()">Cancelar
  </button>
  <button class="button-general d-none d-md-block" style="width: 180px !important;"
          title="Confirmar" (click)="saveParameter()">Confirmar
  </button>
  <div class="col-6 m-0 ps-2 d-block d-md-none">
    <button type="button" class="button-text w-100" title="Cancelar"
            (click)="closeModal()">Cancelar
    </button>
  </div>
  <div class="col-6 m-0 pe-2 d-block d-md-none">
    <button class="button-general col-6" title="Confirmar" (click)="saveParameter()">Confirmar</button>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
