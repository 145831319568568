import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from 'src/app/models/security'

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  getUserInfo(): Observable<UserInfo> {
    return this._http.get<UserInfo>(`${this.url}/security/application/user/info`);
  }

  validateRecaptcha(token: string) : Observable<boolean> {

    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post<boolean>(`${this.url}/security/validate-recaptcha`, JSON.stringify(token), { headers: headers });
  }

}
