import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstants } from '../shared/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var arrayMenu = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU)!);
    // arrayMenu.forEach((item: any) => {
    //   if (item.componentView == route.routeConfig?.path) return true
    // })
    // console.log(route.routeConfig?.path?.substring(0, 4));
    // console.log(state)
    for (var i = 0; i < arrayMenu.length; i++) {
      if (arrayMenu[i].componentView == route.routeConfig?.path?.substring(0, 4)) return true;
      if (arrayMenu[i].componentView == route.routeConfig?.path) return true;
    }

    this.router.navigate(['/home']);
    return false;
  }

}
