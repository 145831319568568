export class Utilities {

  public static downloadBase64File(archivo: string, contentType: string, fileName: string): void {
    const byteString = window.atob(archivo);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {type: contentType});
    this.downloadUrlDataFile(window.URL.createObjectURL(blob), fileName);
  }

  public static downloadBlobFile(blob: Blob | null, nombre: string): void {
    // const link = document.createElement('a');
    this.downloadUrlDataFile(window.URL.createObjectURL(blob), nombre);
  }

  public static downloadUrlDataFile(url: string, nombre: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nombre);
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }


}
