import {Component, OnInit} from '@angular/core';
import {ApplicationService} from '../../services/application.service';
import {ParameterService} from '../../services/parameter.service';
import {finalize, forkJoin} from 'rxjs';
import {AppConstants} from '../../shared/constants/app.constants';
import {CompanyService} from '../../services/company.service';
import {ProfileService} from '../../services/profile.service';
import {Utilities} from '../../shared/utilities';

@Component({
  selector: 'app-profile-report',
  templateUrl: './profile-report.component.html',
  styleUrls: ['./profile-report.component.css']
})
export class ProfileReportComponent implements OnInit {
  loading: boolean = false;
  applicationId: number = 0;
  empresaId: number = 0;
  userStateId: number = 0;
  userTypeId: number = 0;

  listEmpresa: any[] = [];
  listApplications: any[] = [];
  listUserType: any[] = [];
  listUserStates: any[] = [];

  constructor(private applicationService: ApplicationService,
              private parameterService: ParameterService,
              private companyService: CompanyService,
              private profileService: ProfileService,
  ) {

  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      applications: this.applicationService.getApplications(),
      userTypes: this.parameterService.getParameterData(AppConstants.ParametryCode.USER_TYPE),
      empresas: this.companyService.getCompanies()
    })
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(value => {
        this.listApplications = value.applications;
        this.listUserType = value.userTypes;
        this.listEmpresa = value.empresas;
        this.listUserStates = [{id: 0, name: 'Activo'}, {id: 2, name: 'Inactivo'}]
      });
  }

  getReport(): void {
    this.loading = true;
    this.profileService.downloadReport(this.empresaId, this.userStateId, this.applicationId, this.userTypeId)
      .pipe(finalize(() => this.loading = false))
      .subscribe((response: any) => {
        Utilities.downloadBase64File(response.archivo, response.contentType, response.fileName);
      });
  }
}
