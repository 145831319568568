export class MenuNode {
  id: number;
  componentView: string;
  name: string;
  icon: string;
  code: string;
  order: number;
  parentId: number;
  state: boolean;
  options?: MenuNode[];
}

export class Menu {
  id: number;
  applicationId: number;
  parentId: number | null;
  name: string;
  icon: string;
  code: string;
  order: number;
  componentView: string;
  state: boolean;
  createdBy: string;
  updatedBy: string;
}
