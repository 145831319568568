<div class="wrap-view">
  <!--    <div class="information-user d-none d-md-block">-->
  <!--        <div class="inf-user">-->
  <!--            <b>Usuario: </b> <span class="opacidad">{{usersessionId}}</span>-->
  <!--            <b> Agencia: </b> <span class="opacidad">{{agencyName}}</span>-->
  <!--            <b> - Código: </b> <span class="opacidad">{{agencyCode}}</span>-->
  <!--            <b> Canal de Venta: </b> <span class="opacidad">{{channelName}}</span>-->
  <!--        </div>-->
  <!--    </div>-->
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="parameter-title">
    <div class="title-maestro">
      <span style="cursor:pointer" (click)="back()"><i class="fa fa-arrow-circle-o-left"></i> Usuario</span>
    </div>

    <form class="form-cliente" autocomplete="off" [formGroup]="userForm">
      <div class="row">
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Tipo Usuario</label>
          <select class="form-control input-form" formControlName="userTypeId" (change)="changeUserType()">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listUserTypes" value="{{item.key}}">{{item.value}}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Usuario</label>
          <input type="text" class="form-control"
                 (change)="changeAD()"  formControlName="username"/>
        </div>
        <div class="form-group col-sm-4" *ngIf="!isUserTypeInter && mode != 'UPDATE'">
          <label class="subtitle-modal">Contraseña</label>
          <input type="password" autocomplete="off" class="form-control" (change)="changeAD()"
                 formControlName="password"/>
        </div>
        <div class="form-group col-sm-4" *ngIf="mode == 'UPDATE' && !isUserTypeInter">
        </div>
        <div class="form-group col-sm-4" *ngIf="isUserTypeInter">
          <div class="d-block d-md-none w-100">
            <div class="row justify-content-center w-100 m-0">
              <div class="form-group col-8">
                <button type="button" class="button-general inline-btn" title="Validar"
                        (click)="validateAD()">Validar en AD
                </button>
              </div>
            </div>
          </div>
          <div class="form-group col-6 col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
            <button type="button" class="button-general inline-btn" title="Validar"
                    (click)="validateAD()">Validar en AD
            </button>
          </div>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Pais</label>
          <select class="form-control input-form" formControlName="countryId" (change)="changePais();">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listCountries" value="{{item.key}}">{{item.value}}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Empresa</label>
          <select class="form-control input-form" formControlName="companyId">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listCompanies" value="{{item.id}}">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group col-sm-6">
          <label class="subtitle-modal">Nombres</label>
          <input type="text" class="form-control" appLetterOnly formControlName="firstName"/>
        </div>
        <div class="form-group col-sm-6">
          <label class="subtitle-modal">Apellidos</label>
          <input type="text" class="form-control" appLetterOnly formControlName="lastName"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Tipo de documento</label>
          <select class="form-control input-form" formControlName="documentTypeId" (change)="changeTypeDocument()">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listDocumentTypes" value="{{item.key}}">{{item.value}}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Número de documento</label>
          <input maxlength="15"
                 appDocumentNumberDirective
                 [maxLengthDocument]="maxLengthDocument"
                 [typeDocument]="typeDocument"
                 type="text" class="form-control" formControlName="documentNumber"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Correo</label>
          <input type="text" class="form-control" formControlName="email"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Codigo de banco</label>
          <input type="text" appLetterOnly class="form-control" formControlName="bankCode"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Estado</label>
          <select class="form-control input-form" formControlName="state">
            <option disabled selected value="">Seleccione</option>
            <option [value]="true">Activo</option>
            <option [value]="false">Inactivo</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="d-block d-md-none w-100 mt-3">
          <button type="button" class="button-general inline-btn" title="Guardar"
                  (click)="saveUser()">Guardar
          </button>
        </div>
        <div class="form-group col-8 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">
          <button type="button" class="button-general inline-btn" title="Guardar"
                  (click)="saveUser()">Guardar
          </button>
        </div>
      </div>
      <br>
    </form>
     <div class="box-white">
      <div class="grid-control-custom">

        <div class="grid-action-top">
          <div class="result-busq">Asignación de Permisos</div>
          <div class="action-grid-top d-none d-md-block">
            <button type="button" class="button-general" style="width:163px;" (click)="openModal()">Agregar
              Permiso
            </button>
          </div>
        </div>
        <div class="action-grid-top d-block d-md-none " *ngIf="canUpdate">
          <button type="button" class="w-100 button-general" style="width:163px;margin-bottom: 16px"
                  (click)="openModal()">Agregar
            Permiso
          </button>
        </div>
        <div class="grid-control-custom">
          <ngx-datatable #tableListUser style="height:300px"
                         class="material colored-header sm table table-hover" [rows]="perfiles" [columns]="columns"
                         [columnMode]="'force'" [footerHeight]="40" [headerHeight]="60" [rowHeight]="50"
                         [scrollbarV]="true" [scrollbarH]="true" [limit]="5" [loadingIndicator]="loadingIndicator"
                         [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
          </ngx-datatable>
          <ng-template #indexTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{rowIndex + 1}} </strong>
          </ng-template>
          <ng-template #stateTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{row.state == true ? 'Activo' : 'Inactivo'}}</strong>
          </ng-template>
          <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
            <div>
              <button class="btn btn-xs" (click)="openModal(row)">
                <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="box-white">
      <div class="grid-control-custom">

        <div class="grid-action-top">
          <div class="result-busq">Atributos Adicionales</div>
          <div class="action-grid-top d-none d-md-block">
            <button type="button" class="button-general" style="width:163px;" (click)="openModalAdditionalAttribute()">
              Agregar
              Atributo
            </button>
          </div>
        </div>
        <div class="action-grid-top d-block d-md-none ">
          <button type="button" class="w-100 button-general" style="width:163px;margin-bottom: 16px"
                  (click)="openModalAdditionalAttribute()">Agregar Atributo
          </button>
        </div>
        <div class="grid-control-custom">
          <ngx-datatable #tableAdditionalAttribute style="height:300px"
                         class="material colored-header sm table table-hover"
                         [rows]="additionalAttributes"
                         [columns]="columnsAditionalAttributes"
                         [columnMode]="'force'"
                         [footerHeight]="40"
                         [headerHeight]="60"
                         [rowHeight]="50"
                         [scrollbarV]="true"
                         [scrollbarH]="true"
                         [limit]="5"
                         [loadingIndicator]="loadingIndicator"
                         [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
          </ngx-datatable>

          <ng-template #indexAditionalAttributeTemplate let-row="row" let-rowIndex="rowIndex" let-value="value"
                       let-i="index">
            <strong>{{rowIndex + 1}} </strong>
          </ng-template>
          <ng-template #estateAditionalAttributeTemplate let-row="row" let-rowIndex="rowIndex" let-value="value"
                       let-i="index">
            <strong>{{value ? 'Activo' : 'Inactivo'}}</strong>
          </ng-template>
          <ng-template #actionAditionalAttributeTemplate let-row="row" let-value="value" let-i="index">
            <div>
              <button class="btn btn-xs" (click)="openModalAdditionalAttribute(row)">
                <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="d-block d-md-none">
  <div class="float-button-bottom">
    <i id="fa-icon-message" class="fa fa-user" aria-hidden="true" (click)="showMessage()"></i>
    <i id="fa-icon-close" class="fa fa-times" aria-hidden="true" style="display: none;" (click)="hideMessage()"></i>
  </div>
  <div id="float-message" class="div-float-message-bottom" style="display: none;">
    <div id="text-button-float">
      <div class="row">
        <div class="col-4 label-info-popup pr-0">
          Usuario
        </div>
        <div class="col-8 value-info-popup">
          {{usersessionId}}
        </div>
      </div>
      <div class="row">
        <div class="col-4 label-info-popup pr-0">
          Perfil
        </div>
        <div class="col-8 value-info-popup">
          {{usersessionId}}
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
     #userModal="bs-modal"
     tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="userRolForm">
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('applicationId') }">
                Aplicación</label>
              <select class="form-control input-form" formControlName="applicationId"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('applicationId') }"
                      (change)="changeApplication()">
                <option selected value="">Seleccione</option>
                <option *ngFor="let item of listApplications" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('profileId') }">
                Perfil</label>
              <select class="form-control input-form" formControlName="profileId"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('profileId') }">
                <option selected value="">Seleccione</option>
                <option *ngFor="let item of listProfiles" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('agencyId') }">
                Agencia</label>
              <select class="form-control input-form" formControlName="agencyId"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('agencyId') }">
                <option selected value="">Seleccione</option>
                <option *ngFor="let item of listAgencies" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('channelId') }">
                Canal de venta</label>
              <select class="form-control input-form" formControlName="channelId"
                      (change)="changeChannel()"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('channelId') }">
                <option selected value="">Seleccione</option>
                <option *ngFor="let item of listChannels" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('subChannelId') }">
                SubCanal</label>
              <select class="form-control input-form" formControlName="subChannelId"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('subChannelId') }">
                <option selected value="">Seleccione</option>
                <option *ngFor="let item of listSubChannelsCtrl" [value]="item.id">{{item.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('areaId') }">
                Area</label>
              <select class="form-control input-form" formControlName="areaId"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('areaId') }">
                <option selected value="">Seleccione</option>
                <option *ngFor="let item of listAreas" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userRolEvaluateError('state') }">
                Estado</label>
              <select class="form-control input-form" formControlName="state"
                      [ngClass]="{ 'is-invalid-input': userRolEvaluateError('state') }">
                <option selected value="">Seleccione</option>
                <option [ngValue]="true">Activo</option>
                <option [ngValue]="false">Inactivo</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="saveRol()">Asignar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModal()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="saveRol()">Asignar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
     #userAdditionalAttributeModal="bs-modal"
     tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Atributo Adicional</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="userAdditionalAttributeForm">
          <div class="row justify-content-center">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userAdditionalAttributeEvaluateError('key') }">
                Key</label>
              <input type="text" class="form-control"
                     [ngClass]="{ 'is-invalid-input': userAdditionalAttributeEvaluateError('key') }"
                     formControlName="key"/>
            </div>
            <div class="form-group col-sm-12">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userAdditionalAttributeEvaluateError('value') }">
                Valor</label>
              <input type="text" class="form-control"
                     [ngClass]="{ 'is-invalid-input': userAdditionalAttributeEvaluateError('value') }"
                     formControlName="value"/>
            </div>
            <div class="form-group col-sm-12">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': userAdditionalAttributeEvaluateError('state') }">
                Estado</label>
              <select class="form-control input-form"
                      [ngClass]="{ 'is-invalid-input': userAdditionalAttributeEvaluateError('state') }"
                      formControlName="state">
                <option [value]="true">Activo</option>
                <option [value]="false">Inactivo</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModalAdditionalAttribute()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="saveAdditionalAttribute()">Grabar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModalAdditionalAttribute()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="saveAdditionalAttribute()">Grabar</button>
        </div>
      </div>
    </div>
  </div>
</div>

