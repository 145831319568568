import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, Subject, Subscription, takeUntil, timer} from 'rxjs';
import {SessionService} from './shared/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {

  private homePath: string = 'home';
  private loginPath: string = 'login';

  loading: boolean = false;
  stop: boolean = false;
  timerSubscribe: Subscription;
  unsubscribeAll = new Subject();

  constructor(
    public router: Router,
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.sessionService.isAuthenticated) {
        this.loading = true;
      }
    }, 1);

    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    ).pipe(
      takeUntil(this.unsubscribeAll)
    ).subscribe(event => {
      if (this.sessionService.isAuthenticated && (event.url == '/login' || event.url == 'login')) {
        this.router.navigate([this.homePath]);
        this.loading = false;
      }
      if (!this.sessionService.isAuthenticated && (event.url != '/login' && event.url != 'login')) {
        this.router.navigate(['login']);
        this.loading = false;
      }
    });

    if (this.sessionService.isAuthenticated && !this.sessionService.isSessionExpired) {
      this.createTimer();
    }

    this.sessionService.onLoginComplete().pipe(
      takeUntil(this.unsubscribeAll)
    ).subscribe(x => {
      this.createTimer();
    })

    this.sessionService.onLogout().pipe(
      takeUntil(this.unsubscribeAll)
    ).subscribe(x => {
      this.timerSubscribe.unsubscribe();
    })
  }

  createTimer(): void {
    const dateExpiration = new Date(0);
    dateExpiration.setUTCSeconds(this.sessionService.DecodedToken.exp);
    dateExpiration.valueOf();

    const currentDate = new Date();
    const milisecconsForExpiration = dateExpiration.valueOf() - currentDate.valueOf();

    if (milisecconsForExpiration <= 0) {
      this.router.navigate(['login']);
      this.loading = false;
      return;
    }
    this.timerSubscribe = timer(milisecconsForExpiration, 1000).pipe(
      takeUntil(this.unsubscribeAll)
    ).subscribe(val => {
      if (!this.sessionService.isAuthenticated || this.sessionService.isSessionExpired) {
        this.router.navigate(['login']);
        this.loading = false;
        this.timerSubscribe.unsubscribe();
        return;
      }
    });
  }

  navigateTo($event: any, path: any): void {
    if (path == this.homePath)
      this.loading = true;
    else if (path == this.loginPath)
      this.loading = false;

    this.stop = true;
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
  ngAfterViewChecked(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    })
  }
}
