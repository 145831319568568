import {HttpBackend, HttpClient} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LoginService} from 'src/app/services/login.service';
import {SecurityService} from 'src/app/services/security.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {AlertService, MessageSeverity} from 'src/app/shared/services/alert.service';
import {AuditService} from 'src/app/shared/services/audit.service';
import {environment} from 'src/environments/environment';
import {SessionService} from '../../shared/services/session.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {KeysService} from '../../services/keys.service';
// import * as NodeRSA from 'node-rsa';
// import {KeyComponentsPublic} from 'node-rsa';
import * as Forge from 'node-forge';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  token: string;
  loading: boolean = false;
  publicKey: string;
  @Output() navigateToEvent = new EventEmitter<string>();

  passwordChanged = false;

  loginForm: FormGroup;
  pswForm: FormGroup;

  // AUDIT PARAMS
  deviceInfo: any;
  ipAddress = '';
  location = '';
  private httpClient: HttpClient;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private auditService: AuditService,
    private securityService: SecurityService,
    private keysService: KeysService,
    private deviceService: DeviceDetectorService,
    handler: HttpBackend,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.pswForm = this.formBuilder.group({
      // oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      renewPassword: ['', Validators.required],
    });
  }

  logueo() {
    if (this.loginForm.valid) {
      this.validateCaptcha();
    } else {
      this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.PARAMETER_EMPTY, MessageSeverity.error);
    }
  }

  login() {
    this.getAuditData();
    this.loading = true;
    this.keysService.getPublicKey().subscribe({
      next: x => {
        const rsa = Forge.pki.publicKeyFromPem('-----BEGIN PUBLIC KEY-----\n' + x + '\n  -----END PUBLIC KEY-----');

        const usuario = window.btoa(rsa.encrypt(this.loginForm.value['username'], 'RSA-OAEP', {
          md: Forge.md.sha256.create()
        }));

        const password = window.btoa(rsa.encrypt(this.loginForm.value['password'], 'RSA-OAEP', {
          md: Forge.md.sha256.create()
        }));

        this.loginService.GetAuthTokenAsync(usuario, password).subscribe({
          next: (req) => {
            this.sessionService.create(req);
            sessionStorage.setItem(AppConstants.Session.USERID, this.loginForm.value['username']);
            this.loginService.getChangePassword().subscribe({
              next: (req) => {
                if (req) {
                  this.loading = false;
                  this.passwordChanged = req;
                  return;
                }
                this.getUserInfo();
                this.saveAudit();
              },
              error: (err) => {
                this.loading = false;
                // this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
              }
            });
          },
          error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.LOGIN_ERROR, MessageSeverity.error);
          }
        });
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.LOGIN_ERROR, MessageSeverity.error);
      }
    });
  }

  validateCaptcha() {
    this.recaptchaV3Service.execute('login')
      .subscribe((token: string) => {
        this.securityService.validateRecaptcha(token)
          .subscribe((result: boolean) => {

            if (result) {
              this.login();
            } else {
              this.loading = false;
               this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.RECAPTCHA_ERROR, MessageSeverity.error);
            }
          });
      });
  }

  onPasswordChanged() {
    if (this.pswForm.valid) {
      if (this.pswForm.value['newPassword'] == this.pswForm.value['renewPassword']) {
        this.loading = true;
        var data = {password: this.pswForm.value['newPassword']}
        this.loginService.saveChangePassword(data).subscribe({
          next: (req) => {
            this.passwordChanged = false;
            this.loading = false;
            this.pswForm.reset();
            this.loginForm.reset();
            this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.CHANGED_PASSWORD, MessageSeverity.success);
          },
          error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      }else {
        this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.DIFFERENT_PASSWORD, MessageSeverity.error);
      }
    } else {
      this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.PARAMETER_EMPTY, MessageSeverity.error);
    }
  }

  saveAudit() {
    var data = {
      user: sessionStorage.getItem(AppConstants.Session.USERID),
      accessType: this.deviceInfo.deviceType,
      device: this.deviceInfo.device,
      deviceType: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      userIp: this.ipAddress,
      location: this.location
    };
    this.auditService.saveAudit(data).subscribe({
      next: (req) => {
        sessionStorage.setItem(AppConstants.Session.AUDIT, req);
      },
      error: (err) => {
      }
    });
  }

  navigateTo(route: string) {
    this.navigateToEvent.next(route);
  }

  getAuditData() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.location = window.navigator.language;
    this.httpClient.get(environment.ip).subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  getUserInfo() {
    this.securityService.getUserInfo().subscribe({
      next: (req) => {
        sessionStorage.setItem(AppConstants.Session.USER_INFO, JSON.stringify(req));
        sessionStorage.setItem(AppConstants.Session.MENU, JSON.stringify(req.menu));
        this.loginForm.reset();
        this.loading = false;
        this.navigateTo('home');
      },
      error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
      }
    });
  }

}
