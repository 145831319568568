<div class="wrap-view">
  <!--    <div class="information-user d-none d-md-block">-->
  <!--        <div class="inf-user">-->
  <!--            <b>Usuario: </b> <span class="opacidad">{{usersessionId}}</span>-->
  <!--            <b> Agencia: </b> <span class="opacidad">{{agencyName}}</span>-->
  <!--            <b> - Código: </b> <span class="opacidad">{{agencyCode}}</span>-->
  <!--            <b> Canal de Venta: </b> <span class="opacidad">{{channelName}}</span>-->
  <!--        </div>-->
  <!--    </div>-->
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="parameter-title">
    <div class="title-maestro">
      <span style="cursor:pointer" (click)="back()"><i class="fa fa-arrow-circle-o-left"></i> Usuario</span>
    </div>

    <form class="form-cliente" autocomplete="off" [formGroup]="userForm">
      <div class="row">
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Tipo Usuario</label>
          <select class="form-control input-form" formControlName="userTypeId" (change)="changeUserType()">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listUserTypes" value="{{item.key}}">{{item.value}}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Usuario</label>
          <input type="text" class="form-control"  formControlName="username"/>
        </div>
        <div class="form-group col-sm-4" >
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Pais</label>
          <select class="form-control input-form" formControlName="countryId" (change)="changePais();">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listCountries" value="{{item.key}}">{{item.value}}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Empresa</label>
          <select class="form-control input-form" formControlName="companyId">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listCompanies" value="{{item.id}}">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group col-sm-6">
          <label class="subtitle-modal">Nombres</label>
          <input type="text" class="form-control" appLetterOnly formControlName="firstName"/>
        </div>
        <div class="form-group col-sm-6">
          <label class="subtitle-modal">Apellidos</label>
          <input type="text" class="form-control" appLetterOnly formControlName="lastName"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Tipo de documento</label>
          <select class="form-control input-form" formControlName="documentTypeId">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listDocumentTypes" value="{{item.key}}">{{item.value}}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Número de documento</label>
          <input maxlength="15"
                 appDocumentNumberDirective
                 type="text" class="form-control" formControlName="documentNumber"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Correo</label>
          <input type="text" class="form-control" formControlName="email"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Codigo de banco</label>
          <input type="text" appLetterOnly class="form-control" formControlName="bankCode"/>
        </div>
        <div class="form-group col-sm-4">
          <label class="subtitle-modal">Estado</label>
          <select class="form-control input-form" formControlName="state">
            <option disabled selected value="">Seleccione</option>
            <option [value]="true">Activo</option>
            <option [value]="false">Inactivo</option>
          </select>
        </div>
      </div>
<!--      <div class="row">-->
<!--        <div class="d-block d-md-none w-100 mt-3">-->
<!--          <button type="button" class="button-general inline-btn" title="Guardar"-->
<!--                  (click)="saveUser()">Guardar-->
<!--          </button>-->
<!--        </div>-->
<!--        <div class="form-group col-8 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">-->
<!--          <button type="button" class="button-general inline-btn" title="Guardar"-->
<!--                  (click)="saveUser()">Guardar-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
      <br>
    </form>
    <div class="box-white">
      <div class="grid-control-custom">

        <div class="grid-action-top">
          <div class="result-busq">Asignación de Permisos</div>
        </div>
        <div class="grid-control-custom">
          <ngx-datatable #tableListUser style="height:300px"
                         class="material colored-header sm table table-hover" [rows]="perfiles" [columns]="columns"
                         [columnMode]="'force'" [footerHeight]="40" [headerHeight]="60" [rowHeight]="50"
                         [scrollbarV]="true" [scrollbarH]="true" [limit]="5"
                         [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
          </ngx-datatable>
          <ng-template #indexTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{rowIndex + 1}} </strong>
          </ng-template>
          <ng-template #stateTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{row.state == true ? 'Activo' : 'Inactivo'}}</strong>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="box-white">
      <div class="grid-control-custom">

        <div class="grid-action-top">
          <div class="result-busq">Atributos Adicionales</div>
        </div>
        <div class="grid-control-custom">
          <ngx-datatable #tableAdditionalAttribute style="height:300px"
                         class="material colored-header sm table table-hover"
                         [rows]="additionalAttributes"
                         [columns]="columnsAditionalAttributes"
                         [columnMode]="'force'"
                         [footerHeight]="40"
                         [headerHeight]="60"
                         [rowHeight]="50"
                         [scrollbarV]="true"
                         [scrollbarH]="true"
                         [limit]="5"
                         [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
          </ngx-datatable>

          <ng-template #indexAditionalAttributeTemplate let-row="row" let-rowIndex="rowIndex" let-value="value"
                       let-i="index">
            <strong>{{rowIndex + 1}} </strong>
          </ng-template>
          <ng-template #estateAditionalAttributeTemplate let-row="row" let-rowIndex="rowIndex" let-value="value"
                       let-i="index">
            <strong>{{value ? 'Activo' : 'Inactivo'}}</strong>
          </ng-template>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="d-block d-md-none">
  <div class="float-button-bottom">
    <i id="fa-icon-message" class="fa fa-user" aria-hidden="true" (click)="showMessage()"></i>
    <i id="fa-icon-close" class="fa fa-times" aria-hidden="true" style="display: none;" (click)="hideMessage()"></i>
  </div>
  <div id="float-message" class="div-float-message-bottom" style="display: none;">
    <div id="text-button-float">
      <div class="row">
        <div class="col-4 label-info-popup pr-0">
          Usuario
        </div>
        <div class="col-8 value-info-popup">
          {{usersessionId}}
        </div>
      </div>
      <div class="row">
        <div class="col-4 label-info-popup pr-0">
          Perfil
        </div>
        <div class="col-8 value-info-popup">
          {{usersessionId}}
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>

