export class Profile {
  id: number;
  applicationId: number;
  code: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  state: boolean;
}

export class Tree{
    id: number;
    name: string;
    state: boolean;
    options: any[];
    actions: any[];
}
