<div class="modal-header">
  <h5 class="modal-title pull-left">{{titleModal}}</h5>
</div>
<div class="modal-body text-left">
  <div class="box-white">
    <div class="">
      <div class="grid-action-top">
        <div class="result-busq"></div>
        <div class="action-grid-top">
          <button type="button" class="button-general px-4" style="width:163px;" (click)="addDetail()">
            Agregar
          </button>
        </div>
      </div>
      <div class="grid-control-custom">
        <ngx-datatable #tableListParametro
                       [ngClass]="{'table-height-1': tableClass == 'table-height-1','table-height-2': tableClass == 'table-height-2','table-height-3': tableClass == 'table-height-3','table-height-4': tableClass == 'table-height-4','table-height-default': tableClass == 'table-height-default'}"
                       class="material colored-header sm table table-hover"
                       [rows]="rows"
                       [columns]="columns" [columnMode]="'force'"
                       [footerHeight]="40" [headerHeight]="50"
                       [rowHeight]="50"
                       [scrollbarV]="true"
                       [scrollbarH]="true"
                       [limit]="10"
                       [loadingIndicator]="loadingIndicator"
                       [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
        </ngx-datatable>
      </div>
      <ng-template #indexTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
        <strong>{{rowIndex + 1}}</strong>
      </ng-template>

      <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
        <div>
          <button class="btn  btn-xs" (click)="openModalDetailAdd(row)">
            <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-evenly">
  <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
          title="Salir" (click)="closeModal()">Salir
  </button>
  <div class="col-12 m-0 ps-2 d-block d-md-none">
    <button class="button-text w-100" title="Salir"
            (click)="closeModal()">Salir
    </button>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
