import {SelectionModel} from '@angular/cdk/collections';
import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Menu, MenuNode} from 'src/app/models/menu';
import {ApplicationService} from 'src/app/services/application.service';
import {MenuService} from 'src/app/services/menu.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {AlertService, MessageSeverity} from 'src/app/shared/services/alert.service';
import {AuditService} from 'src/app/shared/services/audit.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  // PARAMETRY DATA
  listApplications = [] as any[];
  listTrees = [] as any[];

  // LABELS
  agencyCode: string = 'PE01254878';
  agencyName: string = 'Plaza Norte';
  usersessionId: string = 'jreyes';
  channelName: string = 'otros';

  loading: boolean = false;
  @ViewChild('menuModal') menuModal: ModalDirective;

  // MODAL
  titleModal: string = 'Menu';
  mode: string = 'CREATE';
  flag: string = '0';
  menuSubmit: boolean = false;
  menuId: number = 0;

  // TREE
  nodeAux: MenuNode;
  menuNameForm: string = '';
  treeControl = new NestedTreeControl<MenuNode>(node => node.options);
  dataSource = new MatTreeNestedDataSource<MenuNode>();
  hasChild = (_: number, node: MenuNode) => !!node.options && node.options.length > 0;
  checklistSelection = new SelectionModel<any>(false);

  // AUDIT
  initialActionDate: string;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;

  // FROM
  menuForm: FormGroup;
  formErrors: any = {
    applicationId: '',
    code: '',
    componentView: '',
    parentId: '',
    name: '',
    order: '',
    state: '',
  }

  constructor(
    private menuService: MenuService,
    private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.onPermissions();
    this.getParametersData();
    this.createForm();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_MENU:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_MENU:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_MENU:
          this.canUpdate = true;
          break;
      }
    })
  }

  // PARAMETRY DATA
  getParametersData() {
    this.applicationService.getApplications().subscribe({
      next: (req) => {
        this.listApplications = req;
      }, error: (err) => {
      }
    });
  }

  // FORM
  createForm(): void {
    this.menuForm = this.formBuilder.group({
      applicationId: ['', Validators.required],
      componentView: [''],
      parentId: ['', Validators.required],
      name: ['', Validators.required],
      code: ['', Validators.required],
      icon: [''],
      order: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  // TREE
  // uncheckRecursive(node: MenuNode) {
  //   var child = this.treeControl.getDescendants(node);
  //   this.checklistSelection.isSelected(node) ? this.checklistSelection.toggle(node) : '';
  //   node.checked = this.checklistSelection.isSelected(node);
  //   if (child.length > 0) {
  //     child.forEach((e: any) => this.uncheckRecursive(e))
  //   } else return;
  // }
  onNode(node: MenuNode): void {
    if (this.checklistSelection.isSelected(node)) {
      this.mode = 'CREATE'
      this.nodeAux = new MenuNode();
      this.checklistSelection.toggle(node);
      return;
    }
    this.nodeAux = node;
    // this.dataSource.data.forEach((e: any) => {
    //   this.uncheckRecursive(e);
    // });
    this.checklistSelection.toggle(node);
    this.mode = 'UPDATE'
  }

  // ACTIONS
  getMenu() {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.menuService.getMenu(+this.menuNameForm).subscribe({
      next: (req) => {
        this.listTrees = req;
      }, error: (err) => {
        this.listTrees = []
      }
    });
    this.menuService.getMenuTree(+this.menuNameForm).subscribe({
      next: (req) => {
        this.loading = false;
        // console.log(req);
        this.dataSource.data = req;
      }, error: (err) => {
        this.dataSource.data = [];
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_MENU, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }

  saveMenu() {
    this.flag = '0';
    this.menuSubmit = true;
    if (this.menuForm.valid) {
      this.loading = true;
      var model = new Menu();
      model.applicationId = +this.menuForm.value['applicationId'];
      model.parentId = +this.menuForm.value['parentId'] == 0 ? null : +this.menuForm.value['parentId'];
      model.name = this.menuForm.value['name'];
      model.icon = this.menuForm.value['icon'];
      model.order = +this.menuForm.value['order'];
      model.code = this.menuForm.value['code'];
      model.componentView = this.menuForm.value['componentView'];
      model.state = this.menuForm.value['state'] == '1' ? true : false;
      this.initialActionDate = this.auditService.timeAudit();
      if (this.mode == 'CREATE') {
        model.createdBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canAdd) {
          this.alertService.showMessage(AppConstants.TitleModal.ADD_MENU, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
          this.loading = false;
          return;
        }
        this.menuService.saveMenu(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.closeModal();
            this.getMenu();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.ADD_MENU, AppConstants.Messages.SUCCESS_REGISTER, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.ADD_MENU, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      } else {
        model.id = this.nodeAux.id;
        model.updatedBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canUpdate) {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_MENU, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.info);
          this.loading = false;
          return;
        }
        this.menuService.updateMenu(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.closeModal();
            this.checklistSelection.toggle(this.nodeAux);
            this.getMenu();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_MENU, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.UPDATE_MENU, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      }
    }
  }

  // MODAL
  openModal(isEdit: boolean): void {
    debugger;
    if (this.menuNameForm == '') {
      this.alertService.showMessage(AppConstants.TitleModal.GET_MENU, AppConstants.Messages.MENU_EMPTY, MessageSeverity.error);
      return;
    }
    this.flag = '1';
    if (isEdit) {

      if (this.checklistSelection.isEmpty()) {
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_MENU, AppConstants.Messages.MENU_ITEM, MessageSeverity.error);
        return;
      }
      if (this.checklistSelection.selected.length == 0) {
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_MENU, AppConstants.Messages.MENU_ITEM, MessageSeverity.error);
        return;
      }

      this.nodeAux = this.checklistSelection.selected[0];
      this.mode = 'UPDATE'
      this.setFields();
    } else {
      // this.uncheckRecursive(this.nodeAux);
      this.nodeAux = new MenuNode();
      this.mode = 'CREATE';
      this.loading = false;
      this.clearFields();
    }
    this.menuModal.show();
  }

  closeModal() {
    this.clearFields();
    this.menuModal.hide();
  }

  // AUDIT
  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: this.mode == 'CREATE' ? AppConstants.Actions.ADD : AppConstants.Actions.UPDATE,
      auditAction: this.mode == 'CREATE' ? AppConstants.TitleModal.ADD_MENU : AppConstants.TitleModal.UPDATE_MENU,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  // FIELDS
  setFields(req: any = 0) {
    // console.log(this.nodeAux);
    this.menuForm.controls['applicationId'].setValue(+this.menuNameForm);
    this.menuForm.controls['componentView'].setValue(this.nodeAux.componentView);
    this.menuForm.controls['parentId'].setValue(this.nodeAux.parentId);
    this.menuForm.controls['name'].setValue(this.nodeAux.name);
    this.menuForm.controls['icon'].setValue(this.nodeAux.icon);
    this.menuForm.controls['code'].setValue(this.nodeAux.code);
    this.menuForm.controls['order'].setValue(this.nodeAux.order);
    this.menuForm.controls['state'].setValue(this.nodeAux.state ? 1 : 2);
  }

  clearFields() {
    this.menuForm.controls['applicationId'].setValue(+this.menuNameForm);
    this.menuForm.controls['componentView'].setValue('');
    this.menuForm.controls['parentId'].setValue('');
    this.menuForm.controls['name'].setValue('');
    this.menuForm.controls['icon'].setValue('');
    this.menuForm.controls['code'].setValue('');
    this.menuForm.controls['order'].setValue('');
    this.menuForm.controls['state'].setValue('');
  }

  menuEvaluateError(formControlName: string) {
    var Errores = this.menuForm.get(formControlName)!.errors;
    var Dirty: boolean = this.menuForm.get(formControlName)!.dirty;
    var Touched: boolean = this.menuForm.get(formControlName)!.touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.menuSubmit) {
          if (this.flag == '1') {
            this.formErrors[formControlName] = '';
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }

}
