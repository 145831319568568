import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appLetterOnly]'
})
export class LetterOnlyDirective {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
    ' '
  ];
  private regex: RegExp | null = new RegExp(`[a-zA-Z]`, 'g');
  @Input() allowNumbers = true;

  constructor() {
  }

  @HostListener("keydown", ["$event"])
  public onKeydown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey) ||
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey) ||
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey) ||
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey) ||
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey) ||
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey) ||
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey) ||
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey)
    ) {
      return;
    }
    if (this.allowNumbers) {
      this.regex = new RegExp(/[ña-zÑA-Z0-9]/, 'g');
    } else {
      this.regex = new RegExp(/[ña-zÑA-Z]/, 'g');
    }
    if (this.regex?.test(e.key)) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  }
}
