export class Company {
  id: number;
  code: string;
  name: string;
  accessTypeId: number;
  companyTypeId: number;
  countryId: number;
  state: boolean;
  createdBy: string;
  updatedBy: string;
}
