import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDocumentNumberDirective]'
})
export class DocumentNumberDirective {
  @Input() maxLengthDocument = 8;
  @Input() exactLength = true;
  @Input() typeDocument: 'number' | 'alphabetical' | 'alphanumeric';
  inputElement: HTMLInputElement;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  private regex: RegExp | null = new RegExp(`[a-zA-Z]`, 'g');
  @Input() allowNumbers = true;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener("keydown", ["$event"])
  public onKeydown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey) ||
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey) ||
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey) ||
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey) ||
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey) ||
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey) ||
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey) ||
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey)
    ) {
      return;
    }

    if (this.typeDocument === "number") {
      this.regex = new RegExp(/[0-9]/, 'g');
    } else if (this.typeDocument === "alphabetical") {
      this.regex = new RegExp(/[a-zA-Z]/, 'g');
    } else if (this.typeDocument === "alphanumeric") {
      this.regex = new RegExp(/[a-zA-Z0-9]/, 'g');
    }

    if (!this.regex?.test(e.key)) {
      e.preventDefault();
      return;
    }

    let value = this.forecastValue(e.key);

    if (value.length > this.maxLengthDocument) {
      e.preventDefault();
      return;
    }
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    const oldValue = this.inputElement.value;
    return (
      oldValue.substring(0, selectionStart) +
      key +
      oldValue.substring(selectionEnd)
    );
  }
}
