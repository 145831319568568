import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) {
  }

  private url: string = environment.url_base;

  getProfilesPagination(name: string, pageNumber: number, pageSize: number, sortColumn: string,
                        sortType: string, idApplication: number | null): Observable<any> {
    let params = new HttpParams({
      fromObject: {
        Name: name,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Sort: sortColumn,
        Order: sortType,
      }
    });
    if (idApplication)
      params = params.append('IdAplicacion', idApplication);
    return this._http.get(`${this.url}/profile/pagination`, {params});
  }

  getProfile(id: number): Observable<any> {
    return this._http.get(`${this.url}/profile/${id}`);
  }

  getProfileApplication(id: number): Observable<any> {
    return this._http.get(`${this.url}/profile/application/${id}`);
  }

  getProfileActions(id: number): Observable<any> {
    return this._http.get(`${this.url}/profile/${id}/menu/actions`);
  }

  saveProfile(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/profile`, params, {headers: headers});
  }

  saveActions(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/profile/${data.id}/menu/actions`, params, {headers: headers});
  }

  updateProfile(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/profile/${data.id}`, params, {headers: headers});
  }

  downloadReport(idEmpresa: number, idEstadoUsuario: number, idAplicacion: number, idTipoUsuario: number): Observable<any> {
    return this._http.get(this.url + `/profile/reports`,
      {
        params: new HttpParams({
          fromObject: {
            idEmpresa: idEmpresa,
            idEstadoUsuario: idEstadoUsuario,
            idAplicacion: idAplicacion,
            idTipoUsuario: idTipoUsuario
          }
        })
      });
  }
}
