import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TableColumn} from '@swimlane/ngx-datatable';
import {ParameterService} from 'src/app/services/parameter.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {AlertService} from 'src/app/shared/services/alert.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ParametryDetailComponent} from './parametry-detail/parametry-detail.component';

@Component({
  selector: 'app-parametry',
  templateUrl: './parametry.component.html',
  styleUrls: ['./parametry.component.css']
})
export class ParametryComponent implements OnInit {

  // DATATABLE
  tableClass: string = 'table-height-1';
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as TableColumn[];

  loading: boolean = false;
  @ViewChild('indexTemplate', {static: true}) indexTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', {static: true}) actionTemplate: TemplateRef<any>;

  // PAGINATION OPTIONS
  parameterNameForm: string = '';
  tableOffset: number = 0;
  pageNumber: number = 1;
  offset: number = 0;
  pageSize: number = 10;
  count: number = 0;
  sortColumn: string = '';
  sortType: string = '';
  controloffset: number = 0;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;

  constructor(
    private parameterService: ParameterService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.onPermissions();
    this.createColumsTable();
    this.getParameters();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_PARAMETER:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_PARAMETER:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_PARAMETER:
          this.canUpdate = true;
          break;
      }
    })
  }

  // DATATABLE
  private createColumsTable(): void {
    this.columns = [
      {
        cellTemplate: this.indexTemplate,
        name: '#',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        sortable: false,
        draggable: false,
        resizeable: false
      },
      {
        prop: 'code',
        width: 100,
        name: 'Código',
        cellClass: 'text-right',
        sortable: true,
        draggable: false,
        resizeable: false
      },
      {
        prop: 'name',
        width: 200,
        name: 'Parámetro',
        cellClass: 'text-right',
        sortable: true,
        draggable: false,
        resizeable: false
      },
      {
        prop: 'state',
        name: 'Estado',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        sortable: false,
        draggable: false,
        resizeable: false
      },
      {
        cellTemplate: this.actionTemplate,
        name: 'Acciones',
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        sortable: false,
        draggable: false,
        resizeable: false
      },
    ];
  }

  setPage(pageInfo: any): void {
    if (this.controloffset != pageInfo.offset) {
      this.tableOffset = pageInfo.offset;
      this.pageNumber = pageInfo.offset + 1;
      this.getParameters();
    }
    this.controloffset = pageInfo.offset;
  }

  onSort($event: any): void {
    this.tableOffset = 0;
    this.pageNumber = 1;
    this.sortColumn = $event.column.prop;
    this.sortType = $event.newValue;
    this.getParameters();
  }

  // ACTIONS
  getParameters() {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.parameterService.getParametersPagination(this.parameterNameForm, this.pageNumber, this.pageSize, this.sortColumn, this.sortType).subscribe({
      next: (req) => {
        this.loading = false;
        this.rows = req.results;
        this.rows.forEach((e) => {
          e.state = e.state ? 'Activo' : 'Inactivo'
        })
        this.count = req.totalRows;
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_PARAMETER, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }

  openModalDetail(id: number): void {
    const modal = this.modalService.show(ParametryDetailComponent, {
      initialState: {
        parameterId: id
      },
      class: 'modal-lg ui-custom-gs'
    });
  }

}
