<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8" />
  <title></title>
</head>

<body>
  <div class="bg-login">
    <div class="row">
      <!-- h-100-->
      <div class="col-12 col-md-7 col-lg-8">
        <div class="chispas1 d-none d-lg-block">
          <img src="/assets/images/Chispas 4.png" />
        </div>
        <div class="logo">
          <div class="row d-none d-md-block">
            <div class="col-lg-12">
              <img src="/assets/images/Group.png" />
            </div>
            <div class="col-lg-12">
<!--              <img src="/assets/images/onboarding.png" />-->
            </div>
          </div>
          <div class="row d-sm-block d-md-none">
            <div class="col-lg-12">
              <img src="/assets/images/mobile/logo_login.png" />
            </div>
            <div class="col-lg-12">
              <img src="/assets/images/mobile/logo_name.png" />
            </div>
          </div>
        </div>
        <div class="chispas2 d-none d-lg-block">
          <img src="/assets/images/Chispas 3.png" />
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-4">
        <div class="box-logueo" *ngIf="!passwordChanged">
          <div class="logueo-title">¡Bienvenido(a)!</div>
          <form [formGroup]="loginForm" autocomplete="off">
            <div class="campo">
              <input type="text" class="input-logueo" placeholder="Usuario" formControlName="username" />
            </div>
            <div class="campo">
              <input type="password" class="input-logueo" placeholder="Contraseña" formControlName="password"
                autocomplete="new-password" (keydown.enter)="logueo()" />
            </div>
          </form>
          <div class="btn-logueo">
            <input type='submit' class="btn-iniciar-sesion" (click)="logueo()" value="Ingresar">
          </div>
          <div class="version-logueo">
            <p> Version 1.0.0</p>
          </div>
        </div>

        <div class="box-logueo" *ngIf="passwordChanged">
          <div class="logueo-title">Cambiar Contraseña</div>
          <form [formGroup]="pswForm" autocomplete="off">
            <!-- <div class="campo">
              <input type="password" class="input-logueo" placeholder="Contraseña anterior"
                formControlName="oldPassword" />
            </div> -->
            <div class="campo">
              <input type="password" class="input-logueo" placeholder="Nueva contraseña" formControlName="newPassword"
                autocomplete="new-password" />
            </div>
            <div class="campo">
              <input type="password" class="input-logueo" placeholder="Confirmar contraseña"
                formControlName="renewPassword" />
            </div>
          </form>
          <div class="btn-logueo">
            <input type='submit' class="btn-iniciar-sesion" (click)="onPasswordChanged()" value="Ingresar">
          </div>
          <div class="version-logueo">
            <p> Version 1.0.0</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</body>

</html>
<app-loading *ngIf="loading"></app-loading>
