<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Usuario</span>
    </div>
    <div class="filter-normal">
      <div class="row">
        <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
          <label>Nombre del usuario</label>
          <input class="form-control" placeholder="Buscar por nombre ..." [(ngModel)]="userNameForm"
                 appLetterOnly
                 (keydown.enter)="getUsers()"/>
        </div>
        <div class="d-block d-md-none w-100 mt-3">
          <button type="button" class="button-general inline-btn" title="Buscar"
                  (click)="getUsers()">Buscar
          </button>
        </div>
        <div class="form-group col-8 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">
          <button type="button" class="button-general inline-btn" title="Buscar" (click)="getUsers()">Buscar</button>
        </div>
      </div>
    </div>
    <div class="box-white">
      <div class="grid-control-custom">

        <div class="grid-action-top">
          <div class="result-busq">Resultados de Búsqueda</div>
          <div class="action-grid-top d-none d-md-block" *ngIf="canAdd">
            <button type="button" class="button-general" style="width:163px;" (click)="goToUser()">Agregar</button>
          </div>
        </div>
        <div class="action-grid-top d-block d-md-none " *ngIf="canAdd">
          <button type="button" class="w-100 button-general" style="width:163px;margin-bottom: 16px"
                  (click)="goToUser()">Agregar
          </button>
        </div>
        <div class="grid-control-custom">
          <ngx-datatable #tableUser class="material colored-header sm table table-hover" [rows]="rows"
                         [columns]="columns" [columnMode]="'force'" [scrollbarH]="true" [footerHeight]="40"
                         [headerHeight]="60"
                         [rowHeight]="'auto'" [limit]="pageSize" [count]="count" [offset]="tableOffset"
                         (page)="setPage($event)"
                         [externalSorting]="true" [externalPaging]="true" (sort)="onSort($event)"
                         [loadingIndicator]="loadingIndicator"
                         [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
          </ngx-datatable>

          <ng-template #indexTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{rowIndex + 1}}</strong>
          </ng-template>

          <ng-template #stateTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <input type="checkbox" (click)="changeState(row, $event)"
                   [disabled]="!(canActivateUser && !row.state) && !(canDeactivateUser && row.state)"
                   [checked]="row.state">
          </ng-template>

          <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
            <div class="w-100 text-end">
              <button *ngIf="row.userTypeId == 2 && canReset" class="btn btn-xs" (click)="openModal(row)">
                <i class="fa fa-key" aria-hidden="true" style="color:#B25BC2;" title="Resetear Password"></i>
              </button>
              <button class="btn btn-xs" (click)="goToUser(row.id)" *ngIf="canUpdate">
                <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
              </button>
              <button class="btn btn-xs" (click)="goToUserDetail(row.id)" *ngIf="canViewDetailUser">
                <i class="fa fa-eye" aria-hidden="true" style="color:#B25BC2;" title="Ver Detalle"></i>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>

<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}" #userModal="bs-modal"
     tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="userForm">
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal">
                Usuario</label>
              <input type="text" class="form-control" formControlName="user"/>
            </div>
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': userEvaluateError('password') }">
                Nueva contraseña</label>
              <input type="password" class="form-control" formControlName="password"
                     [ngClass]="{ 'is-invalid-input': userEvaluateError('password') }"/>
            </div>
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': userEvaluateError('rePassword') }">
                Repetir nueva contraseña</label>
              <input type="password" class="form-control" formControlName="rePassword"
                     [ngClass]="{ 'is-invalid-input': userEvaluateError('rePassword') }"/>
            </div>
          </div>
        </form>
<!--        <br>-->
<!--        <div class="row justify-content-center">-->
<!--          <button class="button-text" style="width: 200px;" title="Cancelar" (click)="closeModal()">Cancelar</button>-->
<!--          <button class="button-general" style="width: 200px;" title="Confimar"-->
<!--                  (click)="saveResetPassword()">Confirmar-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="saveResetPassword()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModal()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="saveResetPassword()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
     #changeStateModal="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header justify-content">
        <h5 class="modal-title"> {{changeStateString}} Usuario </h5>
      </div>
      <div class="modal-body text-left">
        <div class="row" [formGroup]="useChangeStateForm">
          <div class="form-group col-sm-12">
            <label class="subtitle-modal">Nombre del usuario</label>
            <input type="text" class="form-control" [value]="userChangeUsername" disabled/>
          </div>
          <div class="form-group col-12" *ngIf="flagDateActivation">
            <label >Fecha inicio de vigencia</label>
            <input type="text"
                   bsDatepicker
                   formControlName="startVigency"
                   [maxDate]="maxDateVigency"
                   class="form-control "/>
          </div>
          <div class="form-group col-12" *ngIf="flagDateActivation">
            <label >Fecha fin de vigencia</label>
            <input type="text"
                   bsDatepicker
                   formControlName="endVigency"
                   [minDate]="minDateVigency"
                   class="form-control "/>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModalChangeState()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="updateState()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModalChangeState()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="updateState()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>
