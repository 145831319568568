import {DatePipe} from '@angular/common';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {UserInfo} from 'src/app/models/security';
import {UserService} from 'src/app/services/user.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {AlertService, MessageSeverity} from 'src/app/shared/services/alert.service';
import {AuditService} from 'src/app/shared/services/audit.service';
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {listLocales} from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [DatePipe]
})
export class UserComponent implements OnInit {

  // DATATABLE
  tableClass: string = 'table-height-1';
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as any[];
  changeStateString: string;

  loading: boolean = false;
  @ViewChild('indexTemplate', { static: true }) indexTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;
  @ViewChild('userModal', { static: true }) userModal: ModalDirective;
  @ViewChild('changeStateModal', { static: true }) changeStateModal: ModalDirective;
  @ViewChild('stateTemplate', { static: true }) stateTemplate: TemplateRef<any>;

  currentDate: Date = new Date();

  // MODAL
  titleModal: string = "Empresa";
  flag: string = "0";
  userSubmit: boolean = false;
  userId: number = 0;

  // PAGINATION OPTIONS
  userNameForm: string = '';
  tableOffset: number = 0;
  pageNumber: number = 1;
  offset: number = 0;
  pageSize: number = 10;
  count: number = 0;
  sortColumn: string = '';
  sortType: string = '';
  controloffset: number = 0;

  // AUDIT
  initialActionDate: string;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  userInfo: UserInfo = JSON.parse(sessionStorage.getItem(AppConstants.Session.USER_INFO)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;
  canReset: boolean = false;
  canActivateUser: boolean = false;
  canDeactivateUser: boolean = false;
  canViewDetailUser: boolean = false;
  userIdChangeState: number;
  userChangeState: boolean;
  userChangeUsername: string;
  userChangeType: number;
  useChangeStateForm: FormGroup;
  flagDateActivation: boolean = false;

  // FORM
  userForm: FormGroup;
  formErrors: any = {
    user: '',
    password: '',
    rePassword: '',
  }

  maxDateVigency: Date;
  minDateVigency: Date;

  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private localeService: BsLocaleService
  ) {
  }

  ngOnInit() {
    const locales = listLocales();
    this.localeService.use('es')
    this.onPermissions();
    this.createColumsTable();
    this.getUsers();
    this.createForm();
    this.createFormUserChangeState();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_USER:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_USER:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_USER:
          this.canUpdate = true;
          break;
        case AppConstants.MenuActions.RESET_PASSWORD:
          this.canReset = true;
          break;
        case AppConstants.MenuActions.ACTIVATE_USER:
          this.canActivateUser = true;
          break;
        case AppConstants.MenuActions.DEACTIVATE_USER:
          this.canDeactivateUser = true;
          break;
        case AppConstants.MenuActions.VIEW_DETAIL_USER:
          this.canViewDetailUser = true;
          break;
      }
    });
  }

  // FORM
  createForm(): void {
    this.userForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      rePassword: ['', Validators.required],
    });
  }

  // DATATABLE
  private createColumsTable(): void {
    this.columns = [
      { cellTemplate: this.indexTemplate, name: 'id', width: 80, maxWidth: 80, minWidth: 80, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      { prop: 'username', name: 'Usuario', width: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      { prop: 'fullName', name: 'Nombre', width: 150, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
      { cellTemplate: this.stateTemplate, name: 'Estado', width: 80, maxWidth: 80, minWidth: 80, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      { cellTemplate: this.actionTemplate, name: 'Acciones', width: 150, maxWidth: 150, minWidth: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
    ];
    /*
    if (this.canChangeState) {
      this.columns = [
        { cellTemplate: this.indexTemplate, name: 'id', width: 80, maxWidth: 80, minWidth: 80, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'username', name: 'Usuario', width: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'fullName', name: 'Nombre', width: 150, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
        { cellTemplate: this.stateTemplate, name: 'Estado', width: 80, maxWidth: 80, minWidth: 80, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { cellTemplate: this.actionTemplate, name: 'Acciones', width: 150, maxWidth: 150, minWidth: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      ];
    } else {
      this.columns = [
        { cellTemplate: this.indexTemplate, name: 'id', width: 80, maxWidth: 80, minWidth: 80, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'username', name: 'Usuario', width: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'fullName', name: 'Nombre', width: 150, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
        { cellTemplate: this.actionTemplate, name: 'Acciones', width: 150, maxWidth: 150, minWidth: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      ];
    }*/
  }
  setPage(pageInfo: any): void {
    if (this.controloffset != pageInfo.offset) {
      this.tableOffset = pageInfo.offset;
      this.pageNumber = pageInfo.offset + 1;
      this.getUsers();
    }
    this.controloffset = pageInfo.offset;
  }
  onSort($event: any): void {
    this.tableOffset = 0;
    this.pageNumber = 1;
    this.sortColumn = $event.column.prop;
    this.sortType = $event.newValue;
    this.getUsers();
  }

  // ACTIONS
  getUsers() {
    if (!this.canGet) return //NOT ALLOWED
    let companyId = this.userInfo.userTypeId == AppConstants.UserType.External ? this.userInfo.companyId : 0;
    this.loading = true;
    this.userService.getUsersPagination(this.userNameForm, this.pageNumber, this.pageSize, this.sortColumn, this.sortType, companyId).subscribe({
      next: (req) => {
        this.loading = false;
        this.rows = req.results;
        this.count = req.totalRows;
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_USER, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }
  saveResetPassword() {
    //NOT ALLOWED
    if (!this.canReset) {
      this.alertService.showMessage(AppConstants.TitleModal.RESET_PASSWORD, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
      return;
    }
    this.flag = "0";
    this.userSubmit = true;
    if (this.userForm.valid) {
      if (this.userForm.value['password'] == this.userForm.value['rePassword']) {
        var data = { password: this.userForm.value['password'] }
        this.initialActionDate = this.auditService.timeAudit();
        this.userService.saveResetPassword(this.userId, data).subscribe({
          next: (req) => {
            this.closeModal();
            this.alertService.showMessage(AppConstants.TitleModal.RESET_PASSWORD, AppConstants.Messages.SUCCESS_PASSWORD, MessageSeverity.success);
            this.saveAuditAction();
          }, error: (err) => {
            // this.alertService.showMessage(AppConstants.TitleModal.RESET_PASSWORD, err.error.errors.messages[0], MessageSeverity.error);
          }
        });
      } else {
        this.alertService.showMessage(AppConstants.TitleModal.RESET_PASSWORD, AppConstants.Messages.DIFFERENT_PASSWORD, MessageSeverity.error);
      }
    }
  }
  goToUser(row: any = 0) {
    this.router.navigate([`/user/add/${row}`]);
  }
  goToUserDetail(row: any = 0) {
    this.router.navigate([`/user/view/${row}`]);
  }
  // MODAL
  openModal(row: any = 0): void {
    this.flag = "1";
    this.userForm.controls['user'].setValue(row.username);
    this.userForm.controls['user'].disable();
    this.userId = row.id;
    this.userModal.show();
  }
  closeModal(): void {
    this.userModal.hide();
    this.setFields();
  }

  // AUDIT
  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: AppConstants.Actions.UPDATE,
      auditAction: AppConstants.TitleModal.RESET_PASSWORD,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  // FIELDS
  setFields() {
    this.userForm.controls['user'].setValue('');
    this.userForm.controls['password'].setValue('');
    this.userForm.controls['rePassword'].setValue('');
  }
  userEvaluateError(formControlName: string) {
    var Errores = this.userForm.get(formControlName)!.errors;
    var Dirty: boolean = this.userForm.get(formControlName)!.dirty;
    var Touched: boolean = this.userForm.get(formControlName)!.touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.userSubmit) {
          if (this.flag == "1") {
            this.formErrors[formControlName] = '';
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }

  changeState(row : any, event: UIEvent) {
    event.preventDefault();
    this.useChangeStateForm.reset();
    this.useChangeStateForm.controls['state'].setValue(!row.state);
    this.useChangeStateForm.controls['userId'].setValue(row.id);
    this.changeStateString = row.state ? 'Inactivar' : 'Activar';
    this.userChangeState = !row.state;
    this.userChangeUsername = row.username;
    this.userIdChangeState = row.id;
    this.changeStateModal.show();

    this.flagDateActivation = row.userTypeId == AppConstants.UserType.External && !row.state ? true : false;
    if(this.flagDateActivation) {
      this.useChangeStateForm.controls['startVigency'].setValue(this.currentDate);
      this.useChangeStateForm.controls['endVigency'].setValue(new Date(new Date().setMonth(this.currentDate.getMonth() + 3)));
    }
  }

  closeModalChangeState() {
    this.changeStateModal.hide();
  }

  updateState() {
    this.userIdChangeState = this.useChangeStateForm.value['userId'];
    let data =  {
      State: this.useChangeStateForm.value['state'],
      StartVigency: this.useChangeStateForm.value['startVigency'],
      EndVigency: this.useChangeStateForm.value['endVigency'],
      UpdatedBy: sessionStorage.getItem(AppConstants.Session.USERID)
    };

    this.loading = true;
    this.userService.changeState(this.userIdChangeState, data).subscribe({
      next: (req) => {
        this.loading = false;
        this.closeModalChangeState();
        this.alertService.showMessage(AppConstants.TitleModal.CHANGE_STATE, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
        this.getUsers()
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.CHANGE_STATE, err.error.errors.messages[0], MessageSeverity.error);
      }
    });
  }

  createFormUserChangeState() {
    this.useChangeStateForm = this.formBuilder.group({
      startVigency: ['', Validators.required],
      endVigency: ['', Validators.required],
      state: [],
      userId: []
    });

    this.useChangeStateForm.get('startVigency')?.valueChanges.subscribe(x => {
      if(x){
        this.minDateVigency = x;
      }
    });
    this.useChangeStateForm.get('endVigency')?.valueChanges.subscribe(x => {
      if(x){
        this.maxDateVigency = x;
      }
    });
  }


}

