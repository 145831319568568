import {Injectable} from '@angular/core';
import {AppConstants} from '../constants/app.constants';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private helper = new JwtHelperService();
  private loginCompleteSubject = new Subject<any>();
  private logoutSubject = new Subject<any>();


  get token(): string {
    return this.getStorage(AppConstants.Session.ACCESS_TOKEN);
  }

  get DecodedToken(): any {
    return  this.helper.decodeToken(this.token);
  }
  //
  // get permission(): UserPermissionModel[] {
  //   return JSON.parse(this.getStorage(this.keyAccessPermission)) as UserPermissionModel[];
  // }

  get isAuthenticated(): boolean {
    if (this.token) {
      return true;
    }
    return false;
  }

  get isSessionExpired():boolean {
    return this.helper.isTokenExpired(this.token);
  }

  private getStorage(key: string): any {
    return sessionStorage.getItem(key);
  }

  private setStorage(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  create(data: any): void {
    this.setStorage(AppConstants.Session.TOKEN_TYPE, data.token_type);
    this.setStorage(AppConstants.Session.ACCESS_TOKEN, data.access_token);
    this.setStorage(AppConstants.Session.REFRESH_TOKEN, data.refresh_token);
    this.createTimeout();
  }

  logout() :void{
    sessionStorage.clear();
    this.logoutSubject.next(this.DecodedToken);
  }

  // setPermision(data: UserPermissionModel[]): void {
  //   this.setStorage(this.keyAccessPermission, JSON.stringify(data));
  // }

  destroy(): void {
    // localStorage.removeItem(this.key);
    sessionStorage.clear();
  }

  createTimeout() {
    this.loginCompleteSubject.next(this.DecodedToken);
  }

  onLoginComplete(): Observable<any> {
    return this.loginCompleteSubject.asObservable();
  }
  onLogout(): Observable<any> {
    return this.logoutSubject.asObservable();
  }
}
