import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  saveAudit(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/audit`, params, { headers: headers });
  }

  saveAuditAction(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/audit/action`, params, { headers: headers });
  }

  timeAudit(){
    const myDate = Date.now();
    const format = 'yyyy-MM-ddTHH:mm:ssZ';
    const locale = 'en-US';
    return formatDate(myDate, format, locale);
  }
  
}
