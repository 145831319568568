import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  getCompaniesPagination(name:string,pageNumber: number, pageSize: number, sortColumn: string, sortType: string): Observable<any> {
    return this._http.get(`${this.url}/company/pagination?Name=${name}&PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sortColumn}&Order=${sortType}`);
  }

  getCompanies(): Observable<any> {
    return this._http.get(`${this.url}/company`);
  }

  getCompany(id:string): Observable<any> {
    return this._http.get(`${this.url}/company/${id}`);
  }

  saveCompany(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/company`, params, { headers: headers });
  }

  updateCompany(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/company/${data.id}`, params, { headers: headers });
  }

  // deleteKid(dataId:any): Observable<any> {
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this._http.delete(this.url + `/api/children/${dataId}`, { headers: headers });
  // }

}
