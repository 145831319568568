import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TableColumn} from '@swimlane/ngx-datatable';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Profile} from 'src/app/models/profile';
import {ApplicationService} from 'src/app/services/application.service';
import {ProfileService} from 'src/app/services/profile.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {AlertService, MessageSeverity} from 'src/app/shared/services/alert.service';
import {AuditService} from 'src/app/shared/services/audit.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  // DATATABLE
  tableClass: string = 'table-height-1';
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as TableColumn[];

  // PARAMETRY DATA
  listApplications = [] as any[];

  // LABELS
  agencyCode: string = 'PE01254878';
  agencyName: string = 'Plaza Norte';
  usersessionId: string = 'jreyes';
  channelName: string = 'otros';

  loading: boolean = false;
  @ViewChild('indexTemplate', {static: true}) indexTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', {static: true}) actionTemplate: TemplateRef<any>;
  @ViewChild('profileModal', {static: true}) profileModal: ModalDirective;

  // MODAL
  titleModal: string = 'Perfil';
  mode: string = '';
  flag: string = '0';
  profileSubmit: boolean = false;
  profileId: number = 0;

  // PAGINATION OPTIONS
  applicationId: number | null = 0;
  profileNameForm: string = '';
  tableOffset: number = 0;
  pageNumber: number = 1;
  offset: number = 0;
  pageSize: number = 10;
  count: number = 0;
  sortColumn: string = '';
  sortType: string = '';
  controloffset: number = 0;

  // AUDIT
  initialActionDate: string;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;

  // FORM
  profileForm: FormGroup;
  formErrors: any = {
    accessTypeId: '',
    companyTypeId: '',
    countryId: '',
    name: '',
    state: '',
  }

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private profileService: ProfileService,
    private auditService: AuditService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.onPermissions();
    this.getParametersData();
    this.createColumsTable();
    this.getProfiles();
    this.createForm();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_PROFILE:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_PROFILE:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_PROFILE:
          this.canUpdate = true;
          break;
      }
    })
  }

  // PARAMETRY DATA
  getParametersData() {
    this.applicationService.getApplications().subscribe({
      next: (req) => {
        this.listApplications = req
      }, error: (err) => {
      }
    });
  }

  // FORM
  createForm(): void {
    this.profileForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      applicationId: ['', Validators.required],
      state: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  // DATATABLE
  private createColumsTable(): void {
    this.columns = [
      {
        cellTemplate: this.indexTemplate, name: 'id', width: 80,
        minWidth: 80,
        maxWidth: 80, sortable: false, draggable: false, resizeable: false, cellClass: 'text-center'
      },
      {
        prop: 'application',
        name: 'Aplicación',
        width: 150,
        sortable: false,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'code',
        name: 'Código',
        width: 80,
        sortable: true,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'name',
        name: 'Perfil',
        width: 150,
        sortable: true,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'state',
        name: 'Estado',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        sortable: false,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        cellTemplate: this.actionTemplate, name: 'Acciones', width: 120,
        minWidth: 120, maxWidth: 120, draggable: false, resizeable: false, cellClass: 'text-center'
      },
    ];
  }

  setPage(pageInfo: any): void {
    if (this.controloffset != pageInfo.offset) {
      this.tableOffset = pageInfo.offset;
      this.pageNumber = pageInfo.offset + 1;
      this.getProfiles();
    }
    this.controloffset = pageInfo.offset;
  }

  onSort($event: any): void {
    this.tableOffset = 0;
    this.pageNumber = 1;
    this.sortColumn = $event.column.prop;
    this.sortType = $event.newValue;
    this.getProfiles();
  }

  // ACTIONS
  getProfiles() {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.profileService.getProfilesPagination(this.profileNameForm, this.pageNumber, this.pageSize, this.sortColumn, this.sortType, this.applicationId).subscribe({
      next: (req) => {
        this.loading = false;
        this.rows = req.results;
        this.rows.forEach((e) => {
          e.state = e.state ? 'Activo' : 'Inactivo'
        })
        this.count = req.totalRows;
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_PROFILE, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }

  saveProfile() {
    this.flag = '0';
    this.profileSubmit = true;
    if (this.profileForm.valid) {
      this.loading = true;
      var model = new Profile();
      model.applicationId = this.profileForm.value['applicationId'];
      model.description = this.profileForm.value['description'];
      model.name = this.profileForm.value['name'];
      model.code = this.profileForm.value['code'];
      model.state = this.profileForm.value['state'] == '1' ? true : false;
      this.initialActionDate = this.auditService.timeAudit();
      if (this.mode == 'CREATE') {
        model.createdBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canAdd) {
          this.alertService.showMessage(AppConstants.TitleModal.ADD_PROFILE, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
          this.loading = false;
          return;
        }
        this.profileService.saveProfile(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.profileModal.hide();
            this.getProfiles();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.ADD_PROFILE, AppConstants.Messages.SUCCESS_REGISTER, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.ADD_PROFILE, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      } else {
        model.id = this.profileId;
        model.updatedBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canUpdate) {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PROFILE, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.info);
          this.loading = false;
          return;
        }
        this.profileService.updateProfile(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.profileModal.hide();
            this.getProfiles();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PROFILE, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PROFILE, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      }
    }
  }

  goToAction(row: any) {
    // console.log(row);
    this.router.navigate(['/profile/action']);
  }

  // MODAL
  openModal(row: any = 0) {
    this.loading = true;
    this.flag = '1';
    if (row != 0) {
      this.mode = 'UPDATE';
      this.profileId = row.id;
      this.profileService.getProfile(row.id).subscribe({
        next: (req) => {
          this.setFields(req);
          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          // this.alertService.showMessage(AppConstants.TitleModal.GET_PROFILE, AppConstants.Messages.NO_DATA, MessageSeverity.error);
        }
      });
    } else {
      this.mode = 'CREATE';
      this.loading = false;
      this.clearFields();
    }
    this.profileModal.show();
  }

  closeModal() {
    this.profileModal.hide();
  }

  // AUDIT
  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: this.mode == 'CREATE' ? AppConstants.Actions.ADD : AppConstants.Actions.UPDATE,
      auditAction: this.mode == 'CREATE' ? AppConstants.TitleModal.ADD_PROFILE : AppConstants.TitleModal.UPDATE_PROFILE,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  // FIELDS
  setFields(req: any = 0) {
    this.profileForm.controls['code'].setValue(req.code);
    this.profileForm.controls['applicationId'].setValue(req.applicationId);
    this.profileForm.controls['description'].setValue(req.description);
    this.profileForm.controls['name'].setValue(req.name);
    this.profileForm.controls['state'].setValue(req.state ? 1 : 2);
  }

  clearFields() {
    this.profileForm.controls['code'].setValue('');
    this.profileForm.controls['applicationId'].setValue('');
    this.profileForm.controls['description'].setValue('');
    this.profileForm.controls['name'].setValue('');
    this.profileForm.controls['state'].setValue('');
  }

  profileEvaluateError(formControlName: string) {
    var Errores = this.profileForm.get(formControlName)!.errors;
    var Dirty: boolean = this.profileForm.get(formControlName)!.dirty;
    var Touched: boolean = this.profileForm.get(formControlName)!.touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.profileSubmit) {
          if (this.flag == '1') {
            this.formErrors[formControlName] = '';
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }

}

