import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {ParameterDetail} from 'src/app/models/parameter';
import {ParameterService} from 'src/app/services/parameter.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {AlertService} from 'src/app/shared/services/alert.service';
import {AuditService} from 'src/app/shared/services/audit.service';
import {ParametryDetailAddComponent} from '../parametry-detail-add/parametry-detail-add.component';

@Component({
  selector: 'app-parametry-detail',
  templateUrl: './parametry-detail.component.html'
})
export class ParametryDetailComponent implements OnInit {
  tableClass: string = 'table-height-4'
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as any[];

  loading: boolean = false;
  @ViewChild('actionTemplate', {static: true}) actionTemplate: TemplateRef<any>;
  @ViewChild('indexTemplate', {static: true}) indexTemplate: TemplateRef<any>;
  @ViewChild('parameterModal', {static: true}) parameterModal: ModalDirective;
  @ViewChild('tableListParametro', {static: true}) tableListParametro: DatatableComponent;

  titleModal: string = 'Detalle parametro';
  mode: string = 'CREATE';
  flag: string = '0';
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  parameterId: number;

  constructor(
    private formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private auditService: AuditService,
    private alertService: AlertService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit() {
    this.onPermissions();
    this.createColumsTable();
    this.parameterId = this.parameterId;
    this.getParameterDetail(this.parameterId);
  }

  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_DETAIL_PARAMETER:
          this.canGet = true;
          break;
      }
    })
  }

  private createColumsTable(): void {
    this.columns = [
      {
        cellTemplate: this.indexTemplate,
        name: '#',
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'key',
        name: 'Llave',
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'code',
        name: 'Código',
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'name',
        name: 'Nombre',
        width: 100,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'value',
        name: 'Valor',
        width: 100,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        prop: 'state',
        name: 'Estado',
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      },
      {
        cellTemplate: this.actionTemplate,
        name: '',
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        draggable: false,
        resizeable: false,
        cellClass: 'text-center'
      }
    ];
  }

  getParameterDetail(id: number): void {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.rows = [];
    this.parameterService.getParametersDetail(id).subscribe({
      next: (req) => {
        this.loading = false;
        let numberResults = req.length;
        this.rows = req;
        switch (numberResults) {
          case 0:
          case 1:
            this.tableClass = 'table-height-1';
            break;
          case 2:
            this.tableClass = 'table-height-2';
            break;
          case 3:
            this.tableClass = 'table-height-3';
            break;
          case 4:
            this.tableClass = 'table-height-4';
            break;
          default:
            this.tableClass = 'table-height-4';
            break;
        }

        this.rows.forEach((e) => {
          e.state = e.state ? 'Activo' : 'Inactivo'
        })
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_PARAMETER, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }


  addDetail(): void {
    const modal = this.modalService.show(ParametryDetailAddComponent, {
      initialState: {
        parameterId: this.parameterId
      },
      class: 'ui-custom-gs'
    });

    modal.onHidden?.subscribe(x => {
      this.getParameterDetail(this.parameterId);
    })
  }

  openModalDetailAdd(parameter: ParameterDetail): void {
    const modal = this.modalService.show(ParametryDetailAddComponent, {
      initialState: {
        parameterId: this.parameterId,
        parameterDetailId: parameter.id
      },
      class: 'ui-custom-gs'
    });

    modal.onHidden?.subscribe(x => {
      this.getParameterDetail(this.parameterId);
    })
  }
}
