<div class="wrap-view" style="padding: 0px 0px 0px 0px;">
    <div class="information-user d-none d-md-block">
      <div class="inf-user">
        <b>Usuario: </b> <span class="opacidad">{{userInfo.username}}</span>
        <b> - Perfil: </b> <span class="opacidad">{{userInfo.profile}}</span>
<!--        <b> Agencia: </b> <span class="opacidad">{{userInfo.agency}}</span>-->
<!--        <b> - Código: </b> <span class="opacidad">{{userInfo.bankCode}}</span>-->
<!--        <b> Canal de Venta: </b> <span class="opacidad">{{userInfo.channel}}</span>-->
      </div>
    </div>
  </div>

  <div class="d-block d-md-none">
    <div class="float-button-bottom">
      <i id="fa-icon-message" class="fa fa-user" aria-hidden="true" (click)="showMessage()"></i>
      <i id="fa-icon-close" class="fa fa-times" aria-hidden="true" style="display: none;" (click)="hideMessage()"></i>
    </div>
    <div id="float-message" class="div-float-message-bottom" style="display: none;">
      <div id="text-button-float">
        <div class="row">
          <div class="col-4 label-info-popup pr-0">
            Usuario
          </div>
          <div class="col-8 value-info-popup">
            {{userInfo.username}}
          </div>
        </div>
        <div class="row">
          <div class="col-4 label-info-popup pr-0">
            Perfil
          </div>
          <div class="col-8 value-info-popup">
            {{userInfo.profile}}
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-4 label-info-popup pr-0">-->
<!--            Agencia-->
<!--          </div>-->
<!--          <div class="col-8 value-info-popup">-->
<!--            {{userInfo.agency}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-4 label-info-popup pr-0">-->
<!--            Código-->
<!--          </div>-->
<!--          <div class="col-8 value-info-popup">-->
<!--            {{userInfo.bankCode}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-4 label-info-popup pr-0">-->
<!--            Canal de Venta-->
<!--          </div>-->
<!--          <div class="col-8 value-info-popup">-->
<!--            {{userInfo.channel}}-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
