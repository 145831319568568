import {NgModule} from '@angular/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CommonModule} from '@angular/common';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {LoadingComponent} from '../components/loading/loading.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

defineLocale('es', esLocale);

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgxDatatableModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    LoadingComponent,
  ],
  providers: [],
  exports: [
    CommonModule,
    ModalModule,
    BsDatepickerModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
  ],
    entryComponents: []
})

export class SharedModule { }
