import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  getMenuTree(id:number): Observable<any> {
    return this._http.get(`${this.url}/menu/application/${id}/tree`);
  }

  getMenu(id:number): Observable<any> {
    return this._http.get(`${this.url}/menu/application/${id}`);
  }

  saveMenu(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/menu`, params, { headers: headers });
  }

  updateMenu(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/menu/${data.id}`, params, { headers: headers });
  }

}
