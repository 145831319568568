import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient) { }

  private url: string = environment.url_base;

  getUsersPagination(name:string,pageNumber: number, pageSize: number, sortColumn: string, sortType: string, companyId: number): Observable<any> {
    return this._http.get(`${this.url}/user/pagination?Name=${name}&PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sortColumn}&Order=${sortType}&CompanyId=${companyId}`);
  }

  getUserRol(id:number): Observable<any> {
    return this._http.get(`${this.url}/user/${id}`);
  }

  validateAD(username:string): Observable<any> {
    return this._http.get(`${this.url}/user/validate-ad`, {
      params: new HttpParams({
        fromObject: {
          username
        }
      })
    });
  }

  saveRol(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/user`, params, { headers: headers });
  }

  updateRol(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/user/${data.id}`, params, { headers: headers });
  }

  saveResetPassword(id:number,data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/user/${id}/restore-password`, params, { headers: headers });
  }

  changeState(id:number, data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/user/${id}/change-state`, params, { headers: headers });
  }

}
