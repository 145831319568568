<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Perfil</span>
    </div>
    <div class="filter-normal">
      <div class="row">
        <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
          <label>Nombre del perfil</label>
          <input class="form-control" placeholder="Buscar por nombre ..." [(ngModel)]="profileNameForm"
                 appLetterOnly
                 (keydown.enter)="getProfiles()"/>
        </div>
        <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
          <label>Aplicación</label>
          <select class="form-control input-form" [(ngModel)]="applicationId">
            <option selected [value]="0">Seleccione</option>
            <option *ngFor="let item of listApplications" value="{{item.id}}">{{item.name}}</option>
          </select>
          <!-- <input class="form-control" placeholder="Buscar por nombre ..." [(ngModel)]="menuNameForm" (keydown.enter)="findMenu()" /> -->
        </div>
        <div class="d-block d-md-none w-100 mt-3">
          <button type="button" class="button-general inline-btn" title="Buscar"
                  (click)="getProfiles()">Buscar</button>
        </div>
        <div class="form-group col-8 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">
          <button type="button" class="button-general inline-btn" title="Buscar" (click)="getProfiles()">Buscar</button>
        </div>
      </div>
    </div>
    <div class="box-white">
      <div class="grid-control-custom">

        <div class="grid-action-top">
          <div class="result-busq">Resultados de Búsqueda</div>
          <div class="action-grid-top d-none d-md-block">
            <button type="button" class="button-general" style="width:163px;" (click)="openModal()">Agregar</button>
          </div>
        </div>
        <div class="action-grid-top d-block d-md-none ">
          <button type="button" class="w-100 button-general" style="width:163px;margin-bottom: 16px" (click)="openModal()">Agregar</button>
        </div>
        <div class="grid-control-custom">
          <ngx-datatable #tableProfile
                         class="material colored-header sm table table-hover"
                         [rows]="rows"
                         [columns]="columns"
                         [columnMode]="'force'"
                         [scrollbarH]="true"
                         [footerHeight]="40"
                         [headerHeight]="60"
                         [rowHeight]="'auto'"
                         [limit]="pageSize"
                         [count]="count"
                         [offset]="tableOffset"
                         (page)="setPage($event)"
                         [externalSorting]="true"
                         [externalPaging]="true"
                         (sort)="onSort($event)"
                         [loadingIndicator]="loadingIndicator"
                         [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
          </ngx-datatable>

          <ng-template #indexTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{rowIndex + 1}}</strong>
          </ng-template>

          <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
            <div>
              <button class="btn  btn-xs" (click)="openModal(row)">
                <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
              </button>
              <span style="margin: 0 -8px 0 -8px;">/</span>
              <button class="btn  btn-xs" (click)="actionModal.openModal(row)">
                <i class="fa fa-tasks" aria-hidden="true" style="color:#B25BC2;" title="Asignar Acción"></i>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>
<app-profile-action #actionModal></app-profile-action>

<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}"
     #profileModal="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-height-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="profileForm">
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal"
                     [ngClass]="{ 'is-invalid-label': profileEvaluateError('code') }">
                Código</label>
              <input type="text" class="form-control" formControlName="code"
                     appLetterOnly
                     [ngClass]="{ 'is-invalid-input': profileEvaluateError('code') }"/>
            </div>
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': profileEvaluateError('name') }">
                Perfil</label>
              <input type="text" class="form-control" formControlName="name"
                     appLetterOnly
                     [ngClass]="{ 'is-invalid-input': profileEvaluateError('name') }"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': profileEvaluateError('applicationId') }">
                Aplicación</label>
              <select class="form-control input-form" formControlName="applicationId"
                      [ngClass]="{ 'is-invalid-input': profileEvaluateError('applicationId') }">
                <option disabled selected value="">Seleccione</option>
                <option *ngFor="let item of listApplications" value="{{item.id}}">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': profileEvaluateError('state') }">
                Estado</label>
              <select class="form-control input-form" formControlName="state"
                      [ngClass]="{ 'is-invalid-input': profileEvaluateError('state') }">
                <option disabled selected value="">Seleccione</option>
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': profileEvaluateError('description') }">
                Descripcion</label>
              <textarea class="form-control" rows="3" formControlName="description"
                        [ngClass]="{ 'is-invalid-input': profileEvaluateError('description') }"></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="saveProfile()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModal()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="saveProfile()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>

