import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApplicationService } from 'src/app/services/application.service';
import { Application } from 'src/app/models/application';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { AuditService } from 'src/app/shared/services/audit.service';
import { TableColumn } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  // DATATABLE
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as TableColumn[];

  loading: boolean = false;
  @ViewChild('indexTemplate', { static: true }) indexTemplate: TemplateRef<any>
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>
  @ViewChild('applicationModal', { static: true }) applicationModal: ModalDirective;

  // MODAL
  titleModal: string = "Aplicación";
  mode: string = "";
  flag: string = "0";
  applicationSubmit: boolean = false;
  applicationId: number = 0;

  // PAGINATION OPTIONS
  ApplicationNameForm: string = '';
  tableOffset: number = 0;
  pageNumber: number = 1;
  offset: number = 0;
  pageSize: number = 10;
  count: number = 0;
  sortColumn: string = '';
  sortType: string = '';
  controloffset: number = 0;

  // AUDIT
  initialActionDate: string;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;

  // FORM
  applicationForm: FormGroup;
  formErrors: any = {
    name: '',
    state: '',
  }

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.onPermissions();
    this.createColumsTable();
    this.getApplications();
    this.createForm();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_APPLICATION:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_APPLICATION:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_APPLICATION:
          this.canUpdate = true;
          break;
      }
    })
  }

  // FORM
  createForm(): void {
    this.applicationForm = this.formBuilder.group({
      clientId: ['', Validators.required],
      name: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  // DATATABLE
  private createColumsTable(): void {
    this.columns = [
      {
        cellTemplate: this.indexTemplate,
        name: 'id',
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        sortable: false, draggable: false, resizeable: false, cellClass: "text-center"
      },
      { prop: 'name', name: 'Nombre', width: 150, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
      {
        cellTemplate: this.actionTemplate, name: 'Acciones', width: 120, minWidth: 120
        , maxWidth: 120, sortable: false, draggable: false, resizeable: false, cellClass: "text-center"
      },
    ];
  }
  setPage(pageInfo: any): void {
    if (this.controloffset != pageInfo.offset) {
      this.tableOffset = pageInfo.offset;
      this.pageNumber = pageInfo.offset + 1;
      this.getApplications();
    }
    this.controloffset = pageInfo.offset;
  }
  onSort($event: any): void {
    this.tableOffset = 0;
    this.pageNumber = 1;
    this.sortColumn = $event.column.prop;
    this.sortType = $event.newValue;
    this.getApplications();
  }

  // ACTIONS
  getApplications() {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.applicationService.getApplicationsPagination(this.ApplicationNameForm, this.pageNumber, this.pageSize, this.sortColumn, this.sortType).subscribe({
      next: (req) => {
        this.loading = false;
        this.rows = req.results;
        this.count = req.totalRows;
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_APPLICATION, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }
  saveApplication() {
    this.flag = "0";
    this.applicationSubmit = true;
    if (this.applicationForm.valid) {
      this.loading = true;
      var model = new Application();
      model.name = this.applicationForm.value['name'];
      model.clientId = this.applicationForm.value['clientId'];
      model.state = this.applicationForm.value['state'] == "1" ? true : false;
      this.initialActionDate = this.auditService.timeAudit();
      if (this.mode == 'CREATE') {
        model.createdBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canAdd) {
          this.alertService.showMessage(AppConstants.TitleModal.ADD_APPLICATION, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
          this.loading = false; return;
        }
        this.applicationService.saveApplication(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.applicationModal.hide();
            this.getApplications();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.ADD_APPLICATION, AppConstants.Messages.SUCCESS_REGISTER, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.ADD_APPLICATION, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      } else {
        model.id = this.applicationId;
        model.updatedBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canUpdate) {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_APPLICATION, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.info);
          this.loading = false; return;
        }
        this.applicationService.updateApplication(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.applicationModal.hide();
            this.getApplications();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_APPLICATION, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.UPDATE_APPLICATION, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      }
    }
  }

  // MODAL
  openModal(row: any = null) {
    this.loading = true;
    this.flag = "1";
    if (row) {
      this.mode = 'UPDATE';
      this.applicationId = row.id;
      this.applicationService.getApplication(row.id).subscribe({
        next: (req) => {
          this.setFields(req);
          this.applicationForm.controls['clientId'].disable();
          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          // this.alertService.showMessage(AppConstants.TitleModal.GET_APPLICATION, AppConstants.Messages.NO_DATA, MessageSeverity.error);
        }
      });
    } else {
      this.mode = 'CREATE';
      this.loading = false;
      this.applicationForm.controls['clientId'].enable();
      this.clearFields();
    }
    this.applicationModal.show();
  }
  closeModal() {
    this.applicationModal.hide();
  }

  // AUDIT
  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: this.mode == 'CREATE' ? AppConstants.Actions.ADD : AppConstants.Actions.UPDATE,
      auditAction: this.mode == 'CREATE' ? AppConstants.TitleModal.ADD_APPLICATION : AppConstants.TitleModal.UPDATE_APPLICATION,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  // FIELDS
  setFields(req: any = 0) {
    this.applicationForm.controls['name'].setValue(req.name);
    this.applicationForm.controls['clientId'].setValue(req.clientId);
    this.applicationForm.controls['state'].setValue(req.state ? 1 : 2);
  }
  clearFields() {
    this.applicationForm.controls['name'].setValue('');
    this.applicationForm.controls['clientId'].setValue('');
    this.applicationForm.controls['state'].setValue('');
  }
  applicationEvaluateError(formControlName: string) {
    var Errores = this.applicationForm.get(formControlName)!.errors;
    var Dirty: boolean = this.applicationForm.get(formControlName)!.dirty;
    var Touched: boolean = this.applicationForm.get(formControlName)!.touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.applicationSubmit) {
          if (this.flag == "1") {
            this.formErrors[formControlName] = '';
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }
}
