import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { LoginService } from '../../../services/login.service';
import { AppConstants } from '../../constants/app.constants';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isNavRipley: boolean = false;
  bandejaActive: boolean = false;

  loginPath: string = 'login';
  loading: boolean = false;
  comentarioAnulacionForm: string = '';
  motivoAnulacionForm: string = '';

  @ViewChild('prototipoModal') prototipoModal: ModalDirective;
  motivosAnulacion = [];

  arrayMenu = [] as any[];

  constructor(private router: Router,
    private appComponent: AppComponent,
    private sessionService: SessionService,
    private authService: LoginService) {
  }

  ngOnInit() {
    this.arrayMenu = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU)!);
    var menuActions:any = [];
    this.arrayMenu.forEach((item: any) => {
      item.actions.forEach((action: any) => {
        menuActions.push(action.id);
      })
    })
    sessionStorage.setItem(AppConstants.Session.MENU_ACTIONS, JSON.stringify(menuActions));
    // sessionStorage.setItem(AppConstants.Session.MENU_ACTIONS, JSON.stringify([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]));// ALL ALLOWED
  }

  showNav() {
    this.isNavRipley = !this.isNavRipley;
  }

  hideNav() {
    this.isNavRipley = !this.isNavRipley;
  }

  goToRoute(route: string) {
    route == 'salir' ? this.logout() : this.router.navigateByUrl(route);
  }

  logout() {
    this.prototipoModal.show();
  }

  confirmLogout() {
    this.authService.logout();
    this.sessionService.logout();
    this.appComponent.navigateTo('', this.loginPath);
  }

  cancelLogout() {
    this.prototipoModal.hide();
  }

  showTransaction() {
    let result = false;
    return result;
  }


}
