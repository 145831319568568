<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}" #actionModal="bs-modal"
     tabindex="-1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <div class="row">
          <p class="form-group col-sm-2">Aplicacion: </p>
          <p class="form-group col-sm-2">{{actionApplication}}</p>
        </div>
        <div class="row">
          <p class="form-group col-sm-2">Perfil: </p>
          <p class="form-group col-sm-2">{{actionProfile}}</p>
        </div>
        <div class="box-white container-fluid" style="padding: 20px;">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <label>
                <strong>Menu disponible</strong>
              </label>
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <mat-checkbox class="checklist-leaf-node" (change)="onNode(node)"
                                [checked]="checklistSelection.isSelected(node)">
                    {{node.name}}
                  </mat-checkbox>
                  <span class="fa-close-icon"><i class="fa fa-search"
                                                 (click)="onActions(node)"></i></span>
                </mat-tree-node>
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                  <div class="mat-tree-node">
                    <a class="btn btn-link btn-xs" mat-icon-button matTreeNodeToggle
                       [attr.aria-label]="'Toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror color-node">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                      </mat-icon>
                    </a>
                    <mat-checkbox (change)="onNode(node)"
                                  [checked]="checklistSelection.isSelected(node)">
                      {{node.name}}
                    </mat-checkbox>
                    <span class="fa-close-icon"><i class="fa fa-search"
                                                   (click)="onActions(node)"></i></span>
                  </div>
                  <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </div>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
            <div class="col">
<!--              <div class="d-block d-md-none w-100">-->
<!--                <div class="row justify-content-center w-100 m-0">-->
<!--                  <div class="form-group col-8">-->
<!--                    <button type="button" class="button-general inline-btn"-->
<!--                            title="Buscar">Buscar-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <!-- <div class="form-group col-8 col-md-4 col-lg-4 col-xl-4 d-none d-md-block">
                  <button type="button" class="button-general inline-btn mb-3"
                      title="Buscar">Buscar</button>
              </div> -->
              <div class="grid-control-custom">
                <ngx-datatable #tableProfile
                               [ngClass]="{'table-height-1': tableClass == 'table-height-1','table-height-2': tableClass == 'table-height-2','table-height-3': tableClass == 'table-height-3','table-height-4': tableClass == 'table-height-4','table-height-default': tableClass == 'table-height-default'}"
                               class="material colored-header sm table table-hover" (activate)="onActivate($event)"
                               [rows]="rows" [columns]="columns" [columnMode]="'force'" [scrollbarV]="true"
                               [headerHeight]="55" [rowHeight]="50" [limit]="4"
                               [loadingIndicator]="loadingIndicator"
                               [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
                </ngx-datatable>

                <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
                  <input class="form-check-input" type="checkbox" [checked]="row.state">
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!--                <div class="row justify-content-center">-->
        <!--                    <button class="button-text" style="width: 200px;" title="Cancelar"-->
        <!--                        (click)="closeModal()">Cancelar</button>-->
        <!--                    <button class="button-general" style="width: 200px;" title="Confimar"-->
        <!--                        (click)="saveActions()">Confirmar</button>-->
        <!--                </div>-->
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="saveActions()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModal()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="saveActions()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
