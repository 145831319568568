import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { AuditService } from 'src/app/shared/services/audit.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  // DATATABLE
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as any[];

  // PARAMETRY DATA
  listCountries = [] as any[];
  listAccessTypes = [] as any[];
  listCompanyTypes = [] as any[];

  loading: boolean = false;
  @ViewChild('indexTemplate') indexTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;
  @ViewChild('companyModal') companyModal: ModalDirective;

  // MODAL
  titleModal: string = "Empresa";
  mode: string = "";
  flag: string = "0";
  companySubmit: boolean = false;
  companyId: number = 0;

  // PAGINATION OPTIONS
  companyNameForm: string = '';
  tableOffset: number = 0;
  pageNumber: number = 1;
  offset: number = 0;
  pageSize: number = 10;
  count: number = 0;
  sortColumn: string = '';
  sortType: string = '';
  controloffset: number = 0;

  // AUDIT
  initialActionDate: string;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;

  // FORM
  companyForm: FormGroup;
  formErrors: any = {
    accessTypeId: '',
    companyTypeId: '',
    countryId: '',
    name: '',
    state: '',
  }

  constructor(
    private companyService: CompanyService,
    private parameterService: ParameterService,
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.onPermissions();
    this.getParametersData();
    this.createColumsTable();
    this.getCompanies();
    this.createForm();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_COMPANY:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_COMPANY:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_COMPANY:
          this.canUpdate = true;
          break;
      }
    })
  }

  // PARAMETRY DATA
  getParametersData() {
    this.parameterService.getParameterData(AppConstants.ParametryCode.COUNTRY).subscribe({
      next: (req) => { this.listCountries = req }, error: (err) => { }
    });
    this.parameterService.getParameterData(AppConstants.ParametryCode.ACCESS_TYPE).subscribe({
      next: (req) => { this.listAccessTypes = req }, error: (err) => { }
    });
    this.parameterService.getParameterData(AppConstants.ParametryCode.COMPANY_TYPE).subscribe({
      next: (req) => { this.listCompanyTypes = req }, error: (err) => { }
    });
  }

  // FORM
  createForm(): void {
    this.companyForm = this.formBuilder.group({
      accessTypeId: ['', Validators.required],
      companyTypeId: ['', Validators.required],
      countryId: ['', Validators.required],
      name: ['', Validators.required],
      code: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  // DATATABLE
  private createColumsTable(): void {
    setTimeout(() => {
      this.columns = [
        { cellTemplate: this.indexTemplate, name: 'Id', width: 100, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'code', name: 'Codigo', width: 80, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'name', name: 'Nombre', width: 150, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'country', name: 'Pais', width: 150, sortable: true, draggable: false, resizeable: false, cellClass: "text-center" },
        { prop: 'state', name: 'Estado', width: 150, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
        { cellTemplate: this.actionTemplate, name: 'Acciones', width: 100, sortable: false, draggable: false, resizeable: false, cellClass: "text-center" },
      ];
    }, 0);
  }
  setPage(pageInfo: any): void {
    if (this.controloffset != pageInfo.offset) {
      this.tableOffset = pageInfo.offset;
      this.pageNumber = pageInfo.offset + 1;
      this.getCompanies();
    }
    this.controloffset = pageInfo.offset;
  }
  onSort($event: any): void {
    this.tableOffset = 0;
    this.pageNumber = 1;
    this.sortColumn = $event.column.prop;
    this.sortType = $event.newValue;
    this.getCompanies();
  }

  // ACTIONS
  getCompanies(): void {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.companyService.getCompaniesPagination(this.companyNameForm, this.pageNumber, this.pageSize, this.sortColumn, this.sortType).subscribe({
      next: (req) => {
        this.loading = false;
        this.rows = req.results;
        this.rows.forEach((e) => { e.state = e.state ? "Activo" : "Inactivo" })
        this.count = req.totalRows;
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_COMPANY, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }
  saveCompany(): void {
    this.flag = "0";
    this.companySubmit = true;
    if (this.companyForm.valid) {
      this.loading = true;
      var model = new Company();
      model.accessTypeId = this.companyForm.value['accessTypeId'];
      model.companyTypeId = this.companyForm.value['companyTypeId'];
      model.countryId = this.companyForm.value['countryId'];
      model.name = this.companyForm.value['name'];
      model.code = this.companyForm.value['code'];
      model.state = this.companyForm.value['state'] == "1" ? true : false;
      this.initialActionDate = this.auditService.timeAudit();
      if (this.mode == 'CREATE') {
        model.createdBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canAdd) {
          this.alertService.showMessage(AppConstants.TitleModal.ADD_COMPANY, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
          this.loading = false; return;
        }
        this.companyService.saveCompany(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.companyModal.hide();
            this.getCompanies();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.ADD_COMPANY, AppConstants.Messages.SUCCESS_REGISTER, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.ADD_COMPANY, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      } else {
        model.id = this.companyId;
        model.updatedBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canUpdate) {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_COMPANY, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.info);
          this.loading = false; return;
        }
        this.companyService.updateCompany(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.companyModal.hide();
            this.getCompanies();
            this.saveAuditAction();
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_COMPANY, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
            // this.alertService.showMessage(AppConstants.TitleModal.UPDATE_COMPANY, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
          }
        });
      }
    }
  }

  // MODAL
  openModal(row: any = 0): void {
    this.loading = true;
    this.flag = "1";
    if (row != 0) {
      this.mode = 'UPDATE';
      this.companyId = row.id;
      this.companyService.getCompany(row.id).subscribe({
        next: (req) => {
          this.setFields(req);
          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          // this.alertService.showMessage(AppConstants.TitleModal.GET_COMPANY, AppConstants.Messages.NO_DATA, MessageSeverity.error);
        }
      });
    } else {
      this.mode = 'CREATE';
      this.loading = false;
      this.clearFields();
    }
    this.companyModal.show();
  }
  closeModal(): void {
    this.companyModal.hide();
  }

  // AUDIT
  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: this.mode == 'CREATE' ? AppConstants.Actions.ADD : AppConstants.Actions.UPDATE,
      auditAction: this.mode == 'CREATE' ? AppConstants.TitleModal.ADD_COMPANY : AppConstants.TitleModal.UPDATE_COMPANY,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  // FIELDS
  setFields(req: any = 0) {
    this.companyForm.controls['accessTypeId'].setValue(req.accessTypeId);
    this.companyForm.controls['companyTypeId'].setValue(req.companyTypeId);
    this.companyForm.controls['countryId'].setValue(req.countryId);
    this.companyForm.controls['name'].setValue(req.name);
    this.companyForm.controls['code'].setValue(req.code);
    this.companyForm.controls['state'].setValue(req.state ? 1 : 2);
  }
  clearFields() {
    this.companyForm.controls['accessTypeId'].setValue('');
    this.companyForm.controls['companyTypeId'].setValue('');
    this.companyForm.controls['countryId'].setValue('');
    this.companyForm.controls['name'].setValue('');
    this.companyForm.controls['code'].setValue('');
    this.companyForm.controls['state'].setValue('');
  }
  companyEvaluateError(formControlName: string) {
    var Errores = this.companyForm.get(formControlName)!.errors;
    var Dirty: boolean = this.companyForm.get(formControlName)!.dirty;
    var Touched: boolean = this.companyForm.get(formControlName)!.touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.companySubmit) {
          if (this.flag == "1") {
            this.formErrors[formControlName] = '';
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }

}

