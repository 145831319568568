import {Component, OnInit} from '@angular/core';
import {UserInfo} from 'src/app/models/security';
import {AppConstants} from '../../constants/app.constants';

declare const hideMessage: any;
declare const showMessage: any;

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent implements OnInit {
  userInfo = JSON.parse(sessionStorage.getItem(AppConstants.Session.USER_INFO)!);

  constructor() {
  }

  ngOnInit() {
  }

  // COMPLEMENTATION
  showMessage(): void {
    showMessage();
  }

  hideMessage(): void {
    hideMessage();
  }
}
