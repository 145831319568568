import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private url: string = environment.url_ef;
  private urlBase: string = environment.url_base;

  constructor(
    private _http: HttpClient,
    private router: Router
  ) { }

  public GetAuthTokenAsync(username: string, password: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    };

    return this._http.post<any>(`${this.url}/connect/token`,
      `client_id=banco.ripley.seguridad&client_secret=C9313ECF-A06C-4C9F-8232-ACB1146A2A13&scope=ms-bp-security&grant_type=password&username=${username}&password=${password}`,
      httpOptions);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login'], { queryParams: { returnUrl: '' } });
  }

  // SECURITY
  getChangePassword(): Observable<any> {
    return this._http.get(`${this.urlBase}/security/user/change-password`);
  }

  saveChangePassword(data:any):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.urlBase + `/security/user/change-password`, params, { headers: headers });
  }

}
