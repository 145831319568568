<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="parameter-title">
    <div class="title-maestro">
      <span>Parámetro</span>
    </div>
    <div class="filter-normal">
      <div class="row">
        <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
          <label >Parametro</label>
          <input class="form-control" placeholder="Buscar por nombre ..." [(ngModel)]="parameterNameForm"
                 appLetterOnly (keydown.enter)="getParameters()"/>
        </div>
        <div class="d-block d-md-none w-100 mt-3">
              <button type="button" class="button-general inline-btn" title="Buscar"
                (click)="getParameters()">Buscar</button>
        </div>
        <div class="form-group col-8 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">
          <button type="button" class="button-general inline-btn" title="Buscar"
            (click)="getParameters()">Buscar</button>
        </div>
      </div>
    </div>
    <div class="box-white" >
      <div class="grid-control-custom">
        <div class="grid-action-top">
          <div class="result-busq">Resultados de Búsqueda</div>
        </div>
        <div class="grid-control-custom">
            <ngx-datatable #tableListParameter
                           class="material colored-header sm table table-hover"
                           [rows]="rows"
                           [columns]="columns"
                           [columnMode]="'force'"
                           [rowHeight]="'auto'"
                           [scrollbarH]="true"
                           [footerHeight]="40"
                           [headerHeight]="60"
                           [limit]="pageSize"
                           [count]="count"
                           [externalPaging]="true"
                           (page)="setPage($event)"
                           [externalSorting]="true"
                           (sort)="onSort($event)"
                           [loadingIndicator]="loadingIndicator"
                           [messages]="{totalMessage: 'Registros encontrados', emptyMessage: 'No hay datos disponibles.'}">
            </ngx-datatable>

          <ng-template #indexTemplate let-row="row" let-rowIndex="rowIndex" let-value="value" let-i="index">
            <strong>{{rowIndex + 1}}</strong>
          </ng-template>

          <ng-template #actionTemplate let-row="row" let-value="value" let-i="index">
            <div>
              <button class="btn  btn-xs" (click)="openModalDetail(row.id)">
                <i class="fa fa-edit" aria-hidden="true" style="color:#B25BC2;" title="Editar"></i>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>
<!--<app-parametry-detail #parameterModal></app-parametry-detail>-->
