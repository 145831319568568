import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ParameterDetail} from '../../../models/parameter';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AlertService, MessageSeverity} from '../../../shared/services/alert.service';
import {ParameterService} from '../../../services/parameter.service';
import {AuditService} from '../../../shared/services/audit.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-parametry-detail-add',
  templateUrl: './parametry-detail-add.component.html',
  styleUrls: ['./parametry-detail-add.component.css']
})
export class ParametryDetailAddComponent implements OnInit {
  titleModal: string = 'Detalle parametro';
  parameterForm: FormGroup;
  loading: boolean = false;
  mode: string = 'CREATE';
  initialActionDate: string;
  parameterId: number = 0;
  parameterDetailId: number = 0;
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canAdd: boolean = false;
  canUpdate: boolean = false;
  parameter: ParameterDetail;

  constructor(
    private formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private auditService: AuditService,
    private alertService: AlertService,
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
    console.log(this.parameter);
    this.onPermissions();
    if (this.parameterDetailId) {
      this.mode = 'UPDATE';
      this.createForm();
      this.loading = true;
      this.parameterService.getParameterDetail(this.parameterDetailId).subscribe({
        next: (req) => {
          this.parameter = req;
          this.loading = false;
          this.parameterForm.patchValue(this.parameter);
        }, error: (err) => {
          this.loading = false;
        }
      });
    } else {
      this.mode = 'CREATE';
      this.createForm();
    }
  }

  createForm() {
    this.parameterForm = this.formBuilder.group({
      id: '',
      key: ['', Validators.required],
      code: ['', Validators.required],
      value: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  saveParameter() {
    if (this.parameterForm.valid) {
      this.loading = true;
      var model = new ParameterDetail();
      model.key = this.parameterForm.value['key'];
      model.code = this.parameterForm.value['code'];
      model.value = this.parameterForm.value['value'];
      model.state = this.parameterForm.value['state'];
      this.initialActionDate = this.auditService.timeAudit();
      if (this.mode == 'CREATE') {
        model.parameterId = this.parameterId;
        model.createdBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        //NOT ALLOWED
        if (!this.canAdd) {
          this.alertService.showMessage(AppConstants.TitleModal.ADD_PARAMETER, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
          this.loading = false;
          return;
        }
        this.parameterService.saveParameterDetail(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.alertService.showMessage(AppConstants.TitleModal.ADD_PARAMETER, AppConstants.Messages.SUCCESS_REGISTER, MessageSeverity.success);
            this.saveAuditAction();
            this.closeModal();
          }, error: (err) => {
            this.loading = false;
          }
        });
      } else {
        model.id = this.parameterForm.value['id'];
        model.updatedBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
        if (!this.canUpdate) {
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PARAMETER, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.info);
          this.loading = false;
          return;
        }
        this.parameterService.updateParameterDetail(model).subscribe({
          next: (req) => {
            this.loading = false;
            this.saveAuditAction();
            this.closeModal();
            this.alertService.showMessage(AppConstants.TitleModal.UPDATE_PARAMETER, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
          }, error: (err) => {
            this.loading = false;
          }
        });
      }
    } else {
      this.alertService.showMessage(AppConstants.TitleModal.GET_PARAMETER, AppConstants.Messages.PARAMETER_EMPTY, MessageSeverity.error);
    }
  }

  saveAuditAction(): void {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: this.mode == 'CREATE' ? AppConstants.Actions.ADD : AppConstants.Actions.UPDATE,
      auditAction: this.mode == 'CREATE' ? AppConstants.TitleModal.ADD_PARAMETER : AppConstants.TitleModal.UPDATE_PARAMETER,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.ADD_DETAIL_PARAMETER:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_DETAIL_PARAMETER:
          this.canUpdate = true;
          break;
      }
    })
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
