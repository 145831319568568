import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  // subscription: Subscription;
  loginActive: boolean = false;

  constructor(/*private router: Router, private activatedRoute: ActivatedRoute *//*private emitterService: EmitterService*/) {
    // this.subscription = this.emitterService.getMessage().subscribe(message => {
    //   this.homeActive = message == "/home" ? true : false;
    // });
    /*  this.loginActive = activatedRoute._routerState.snapshot.url == '/login' || activatedRoute._routerState.snapshot.url == 'login';
        router.events.pipe(
          filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        ).subscribe(event => {
          this.loginActive = event.url == '/login' || event.url == 'login';
          console.log(event);
        })
    */
  }

  ngOnInit() {
  }

}
