export class Parameter {
    id: number;
    name: string;
    createdBy: string;
    state: boolean;
}
export class ParameterDetail {
    parameterId: number;
    id: number;
  key: number;
  code: string;
    value: string;
    createdBy: string;
    updatedBy: string;
    state: boolean;
}
