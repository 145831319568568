<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png" />
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Menu</span>
    </div>
    <div class="filter-normal">
      <div class="row">
        <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
          <label >Buscar por Aplicación</label>
          <select class="form-control input-form" [(ngModel)]="menuNameForm">
            <option disabled selected value="">Seleccione</option>
            <option *ngFor="let item of listApplications" value="{{item.id}}">{{item.name}}</option>
          </select>
        </div>
        <div class="d-block d-md-none w-100 mt-3">
          <button type="button" class="button-general inline-btn" title="Buscar"
                  (click)="getMenu()">Buscar</button>
        </div>
        <div class="form-group col-8 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">
          <button type="button" class="button-general inline-btn" title="Buscar"
                  (click)="getMenu()">Buscar</button>
        </div>
      </div>
    </div>
    <div class="box-white container-fluid" style="padding: 20px;">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <label>
            <strong>Menu disponible</strong>
          </label>
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <a class="no-style-a"
                 [class.node-disabled]="!node.state"
                 [ngClass]="checklistSelection.isSelected(node) ?'selected-node':'fw-normal'"
                 (click)="onNode(node)">{{node.name}}</a>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <div class="mat-tree-node">
                <a class="btn btn-link btn-xs" mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror color-node">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </a>
                <a class="no-style-a"
                   [class.node-disabled]="!node.state"
                   [ngClass]="checklistSelection.isSelected(node) ?'selected-node':'fw-normal'"
                   (click)="onNode(node)">{{node.name}}</a>
              </div>
              <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
        <div class="col">
          <div class="d-block d-md-none w-100 mt-3">
            <button type="button" class="button-general inline-btn" title="Agregar"
                    (click)="openModal(false)">Agregar</button>
          </div>
          <div class="form-group col-8 col-md-6 col-lg-5 col-xl-4 d-none d-md-block">
            <button type="button" class="button-general inline-btn" title="Agregar"
                    (click)="openModal(false)">Agregar</button>
          </div>
          <div class="d-block d-md-none w-100 ">
            <button type="button" class="button-general inline-btn" title="Editar"
                    (click)="openModal(true)">Editar</button>
          </div>
          <div class="form-group col-8 col-md-6 col-lg-5 col-xl-4 d-none d-md-block">
            <button type="button" class="button-general inline-btn" title="Editar"
                    (click)="openModal(true)">Editar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>

<div class="modal fade" bsModal [config]="{ backdrop: 'static', keyboard: false,animated:false}" #menuModal="bs-modal"
  tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content modal-company-add">
      <div class="modal-header">
        <h5 class="modal-title pull-left">{{titleModal}}</h5>
      </div>
      <div class="modal-body text-left">
        <form class="form-cliente" autocomplete="offs" [formGroup]="menuForm">
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('parentId') }">
                Menu superior</label>
              <select class="form-control input-form" formControlName="parentId"
              [ngClass]="{ 'is-invalid-input': menuEvaluateError('parentId') }">
                <option disabled selected value="">Seleccione</option>
                <option value="0">Padre</option>
                <option *ngFor="let item of listTrees" value="{{item.id}}">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('code') }">
                Código</label>
              <input appLetterOnly type="text" class="form-control" formControlName="code"
              [ngClass]="{ 'is-invalid-input': menuEvaluateError('code') }"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('name') }">
                Menu</label>
              <input appLetterOnly type="text" class="form-control" formControlName="name"
                     [ngClass]="{ 'is-invalid-input': menuEvaluateError('name') }"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('order') }">
                Orden</label>
              <input appNumberOnly type="text" class="form-control" formControlName="order"
              [ngClass]="{ 'is-invalid-input': menuEvaluateError('order') }"/>
            </div>
            <div class="form-group col-sm-6">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('state') }">
                Estado</label>
              <select class="form-control input-form" formControlName="state"
              [ngClass]="{ 'is-invalid-input': menuEvaluateError('state') }">
                <option disabled selected value="">Seleccione</option>
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('icon') }">
                Icono</label>
              <input  type="text" class="form-control" formControlName="icon"
                     [ngClass]="{ 'is-invalid-input': menuEvaluateError('icon') }"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="subtitle-modal" [ngClass]="{ 'is-invalid-label': menuEvaluateError('componentView') }">
                Vista de Menu</label>
              <input type="text" class="form-control" formControlName="componentView"
              [ngClass]="{ 'is-invalid-input': menuEvaluateError('componentView') }"/>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-evenly">
        <button type="button" class="button-text d-none d-md-block" style="width: 180px !important;"
                title="Cancelar" (click)="closeModal()">Cancelar
        </button>
        <button class="button-general d-none d-md-block" style="width: 180px !important;"
                title="Confirmar" (click)="saveMenu()">Confirmar
        </button>
        <div class="col-6 m-0 ps-2 d-block d-md-none">
          <button type="button" class="button-text w-100" title="Cancelar"
                  (click)="closeModal()">Cancelar
          </button>
        </div>
        <div class="col-6 m-0 pe-2 d-block d-md-none">
          <button class="button-general col-6" title="Confirmar" (click)="saveMenu()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>
