import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ParameterService} from 'src/app/services/parameter.service';
import {ApplicationService} from 'src/app/services/application.service';
import {ProfileService} from 'src/app/services/profile.service';
import {AppConstants} from 'src/app/shared/constants/app.constants';
import {CompanyService} from 'src/app/services/company.service';
import {UserService} from 'src/app/services/user.service';
import {AlertService, MessageSeverity} from 'src/app/shared/services/alert.service';
import {AuditService} from 'src/app/shared/services/audit.service';
import {forkJoin, of} from "rxjs";

declare const hideMessage: any;
declare const showMessage: any;

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
  userInfo = JSON.parse(sessionStorage.getItem(AppConstants.Session.USER_INFO)!);

  // DATATABLE
  loadingIndicator: boolean = false;
  columns = [] as any[];
  columnsAditionalAttributes = [] as any[];
  typeDocument: 'number' | 'alphabetical' | 'alphanumeric' = 'number';
  maxLengthDocument: number = 8;

  // PARAMETRY DATA
  listApplications = [] as any[];
  listProfiles = [] as any[];
  listAreas = [] as any[];
  listAgencies = [] as any[];
  listChannels = [] as any[];
  listSubChannels = [] as any[];
  listSubChannelsCtrl = [] as any[];
  listCountries = [] as any[];
  listCompanyTypes = [] as any[];
  listUserTypes = [] as any[];
  listDocumentTypes = [] as any[];
  listCompanies = [] as any[];
  listCompaniesAll = [] as any[];
  // LABELS
  agencyCode: string = 'PE01254878';
  agencyName: string = 'Plaza Norte';
  usersessionId: string = 'jreyes';
  channelName: string = 'otros';

  get perfiles(): any {
    return this.userForm?.controls["profiles"]?.value || [];
  }

  get additionalAttributes(): any {
    return this.userForm?.controls["additionalAttributes"]?.value || [];
  }

  loading: boolean = false;
  @ViewChild('indexTemplate') indexTemplate: TemplateRef<any>
  @ViewChild('stateTemplate') stateTemplate: TemplateRef<any>
  @ViewChild('indexAditionalAttributeTemplate') indexAditionalAttributeTemplate: TemplateRef<any>
  @ViewChild('estateAditionalAttributeTemplate') estateAditionalAttributeTemplate: TemplateRef<any>
  @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>
  @ViewChild('actionAditionalAttributeTemplate') actionAditionalAttributeTemplate: TemplateRef<any>
  @ViewChild('userModal') userModal: ModalDirective;
  @ViewChild('userAdditionalAttributeModal') userAdditionalAttributeModal: ModalDirective;
  @ViewChild('tableListUser') tableListUser: DatatableComponent;
  @ViewChild('tableAdditionalAttribute') tableAdditionalAttribute: DatatableComponent;

  // MODAL
  titleModal: string = "Permisos";
  mode: string = "CREATE";
  flag: string = "0";
  userSubmit: boolean = false;
  userId: number = 0;
  varValidateAD: boolean = false;

  // AUDIT
  initialActionDate: string;

  // PERMISSIONS
  menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
  canGet: boolean = false;
  canAdd: boolean = false;
  canUpdate: boolean = false;

  // FORM
  isUserTypeInter: boolean = false;
  userForm: FormGroup;
  userRolForm: FormGroup;
  userAdditionalAttributeForm: FormGroup;
  formErrors: any = {
    applicationId: '',
    profileId: '',
    agencyId: '',
    channelId: '',
    subChannelId: '',
    areaId: '',
    state: '',
  }

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private applicationService: ApplicationService,
    private profileService: ProfileService,
    private parameterService: ParameterService,
    private userService: UserService,
    private alertService: AlertService,
    private auditService: AuditService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.onPermissions();
    this.createForm();
    this.getParametersData();
    this.createColumsTable();
  }
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_USER:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_USER:
          this.canAdd = true;
          break;
        case AppConstants.MenuActions.UPDATE_USER:
          this.canUpdate = true;
          break;
      }
    })
  }
  getParametersData() {
    this.loading = true;
    this.userId = +this.activatedRoute.snapshot.paramMap.get('id')!;

    forkJoin({
      agencias: this.parameterService.getMasterDataAgency(),
      areas: this.parameterService.getMasterDataArea(),
      canales: this.parameterService.getMasterDataChannel(),
      subCanales: this.parameterService.getMasterDataSubChannel(),
      paises: this.parameterService.getParameterData(AppConstants.ParametryCode.COUNTRY),
      tipoEmpresa: this.parameterService.getParameterData(AppConstants.ParametryCode.COMPANY_TYPE),
      tipoUsuario: this.parameterService.getParameterData(AppConstants.ParametryCode.USER_TYPE),
      tipoDocumento: this.parameterService.getParameterData(AppConstants.ParametryCode.DOCUMENT_TYPE),
      applicaciones: this.applicationService.getApplications(),
      empresas: this.companyService.getCompanies(),
      usuario: this.userId == 0 ? of(null) : this.userService.getUserRol(this.userId)
    }).subscribe(req => {
      this.listAgencies = req.agencias;
      this.listAreas = req.areas;
      this.listChannels = req.canales;
      this.listSubChannels = req.subCanales;
      this.listSubChannelsCtrl = req.subCanales;
      this.listCountries = req.paises;
      this.listCompanyTypes = req.tipoEmpresa;
      this.listUserTypes = req.tipoUsuario;
      this.listDocumentTypes = req.tipoDocumento;
      this.listApplications = req.applicaciones;
      this.listCompaniesAll = req.empresas;
      if (this.userId != 0) {
        for (const x of req.usuario.profiles) {
          x.application = this.listApplications.filter((z: any) => z.id == x.applicationId)[0].name;
        }

        this.setFieldsUser(req.usuario);
        this.userForm.get("password")?.clearValidators();
        (this.userForm.get("password") as FormControl).updateValueAndValidity()
      } else {
        if (this.userInfo.userTypeId == AppConstants.UserType.External) {
          this.userForm.patchValue({
            userTypeId: this.userInfo.userTypeId,
            countryId: this.userInfo.countryId,
            companyId: this.userInfo.companyId
          });
          this.userForm.controls['userTypeId'].disable();
          this.userForm.controls['companyId'].disable();
          this.changePais();
          this.changeUserType();
        }
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }
  createForm() {
    this.userForm = this.formBuilder.group({
      id: [],
      userTypeId: [{value: '', disabled: false}, Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      countryId: ['', Validators.required],
      companyId: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      documentTypeId: ['', Validators.required],
      documentNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bankCode: ['', Validators.required],
      state: ['', Validators.required],
      additionalAttributes: [''],
      profiles: [''],
    });
    this.userRolForm = this.formBuilder.group({
      id: [''],
      applicationId: ['', Validators.required],
      profileId: ['', Validators.required],
      agencyId: ['', Validators.required],
      channelId: ['', Validators.required],
      subChannelId: ['', Validators.required],
      areaId: ['', Validators.required],
      application: [''],
      profile: [''],
      state: [true, Validators.required],
    });
    this.userAdditionalAttributeForm = this.formBuilder.group({
      id: [''],
      userId: [''],
      key: ['', Validators.required],
      value: ['', Validators.required],
      state: [true, Validators.required],
    });
  }

  createColumsTable(): void {
    setTimeout(() => {
      this.columns = [
        {cellTemplate: this.indexTemplate, name: 'Id', width: 40, draggable: false, resizeable: false},
        {prop: 'application', name: 'Aplicación', width: 80, draggable: false, resizeable: false},
        {prop: 'profile', name: 'Perfil', width: 150, draggable: false, resizeable: false},
        {cellTemplate: this.stateTemplate, name: 'Estado', width: 50, draggable: false, resizeable: false},
        {
          prop: 'state',
          cellTemplate: this.actionTemplate,
          name: 'Acciones',
          width: 120,
          draggable: false,
          resizeable: false
        },
      ];

      this.columnsAditionalAttributes = [
        {
          cellTemplate: this.indexAditionalAttributeTemplate,
          name: 'Id',
          width: 40,
          draggable: false,
          resizeable: false
        },
        {prop: 'key', name: 'Key', width: 80, draggable: false, resizeable: false},
        {prop: 'value', name: 'Valor', width: 150, draggable: false, resizeable: false},
        {
          cellTemplate: this.estateAditionalAttributeTemplate,
          prop: 'state', name: 'Estado', width: 50, draggable: false, resizeable: false
        },
        {
          cellTemplate: this.actionAditionalAttributeTemplate,
          name: 'Acciones',
          width: 120,
          draggable: false,
          resizeable: false
        },
      ];

    }, 0);
  }

  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: this.mode == 'CREATE' ? AppConstants.Actions.ADD : AppConstants.Actions.UPDATE,
      auditAction: this.mode == 'CREATE' ? AppConstants.TitleModal.ADD_USER : AppConstants.TitleModal.UPDATE_USER,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }
  evaluateError(form: FormGroup, formControlName: string) {
    var Errores = form.get(formControlName)!.errors;
    var Dirty: boolean = form.get(formControlName)!.dirty;
    var Touched: boolean = form.get(formControlName)!.touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.userSubmit) {
          if (this.flag == "1") {
            this.formErrors[formControlName] = '';
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }
  validateDocumentNumber(documentNumber: string, documentType: number): boolean {
    if (!documentNumber) return false;
    if (documentType == 1) {//DNI
      if (documentNumber.length !== 8) {
        return false;
      }
    }

    if (documentType == 2) {
      if (documentNumber.length > 12) {
        return false;
      }
    }

    if (documentType == 2) {
      if (documentNumber.length !== 11) {
        return false;
      }
    }
    return true;
  }

  setFieldsUser(req: any = 0) {
    this.userForm.patchValue(req);
    if (this.userInfo.userTypeId == AppConstants.UserType.External) {
      this.userForm.controls['userTypeId'].disable();
      this.userForm.controls['companyId'].disable();
    }
    this.changePais();
    this.changeUserType();
  }

  changePais(): void {
    const data = this.userForm.getRawValue();
    if (data && data.countryId) {
      this.listCompanies = this.listCompaniesAll.filter(x => x.countryId == data.countryId);
    }
  }

  changeTypeDocument(): void {
    if (this.userForm.value['documentTypeId'] == 1) {
      this.maxLengthDocument = 8;
      this.typeDocument = 'number';
    } else if (this.userForm.value['documentTypeId'] == 3) {
      this.maxLengthDocument = 11;
      this.typeDocument = 'number';
    } else {
      this.maxLengthDocument = 12;
      this.typeDocument = 'alphanumeric';
    }

    this.userForm.get("documentNumber")?.setValue('');
  }

  validateAD() {
    var username = this.userForm.value['username'];
    this.loading = true;
    this.userService.validateAD(username).subscribe({
      next: (req) => {
        this.loading = false;
        if (req) {
          this.userForm.get('firstName')?.setValue(req.firstName);
          this.userForm.get('lastName')?.setValue(req.lastName);
          this.userForm.get('documentNumber')?.setValue(req.documentNumber);
          this.userForm.get('email')?.setValue(req.email);
          this.userForm.get('bankCode')?.setValue(req.bankCode);

          switch (req.documentType) {
            case 'DNI':
              this.userForm.get('documentTypeId')?.setValue(1);
              break;
            case 'CE':
              this.userForm.get('documentTypeId')?.setValue(2);
              break;
            default:
              this.userForm.get('documentTypeId')?.setValue(null);
              break;
          }

          this.alertService.showMessage(AppConstants.TitleModal.VALIDATE_USER, AppConstants.Messages.SUCCESS_VALIDATE, MessageSeverity.success);
        } else {
          this.alertService.showMessage(AppConstants.TitleModal.VALIDATE_USER, "El usuario no se encontro en el AD.", MessageSeverity.error);
        }
        this.varValidateAD = req;
      }, error: (err) => {
        this.loading = false;
      }
    });
  }

  changeUserType() {
    this.isUserTypeInter = this.userForm.value['userTypeId'] == AppConstants.UserType.Internal;
  }

  changeApplication() {
    let id = this.userRolForm.value['applicationId'];
    this.listProfiles = [];
    this.profileService.getProfileApplication(id).subscribe({
      next: (req) => {
        this.listProfiles = req
      }, error: (err) => {
      }
    });
  }

  changeChannel() {
    this.listSubChannelsCtrl = this.listSubChannels.filter((x: any) => x.channelId == this.userRolForm.value['channelId']);
  }

  changeAD() {
    this.varValidateAD = false;
  }

  saveUser() {
    const titleModals = this.userId === 0 ? AppConstants.TitleModal.ADD_USER : AppConstants.TitleModal.UPDATE_USER;

    const dataUser = this.userForm.getRawValue();
    if (dataUser.userTypeId == AppConstants.UserType.External) {
      this.varValidateAD = true
    } else {
      dataUser.password = '';
    }

    if (dataUser.userTypeId == AppConstants.UserType.Internal && !this.varValidateAD) {
      this.alertService.showMessage(titleModals, AppConstants.Messages.ACTIVE_DIRECTORY, MessageSeverity.info);
      return;
    }
    if (!this.userForm.valid) {
      if (!this.userForm.controls['email'].valid) {
        this.alertService.showMessage(titleModals, AppConstants.Messages.EMAIL_INVALID, MessageSeverity.error);
        return;
      } else {
        this.alertService.showMessage(titleModals, AppConstants.Messages.PARAMETER_EMPTY, MessageSeverity.error);
        return;
      }
    }
    if (!this.validateDocumentNumber(dataUser.documentNumber, dataUser.documentTypeId)) {
      this.alertService.showMessage(titleModals, AppConstants.Messages.DOCUMENT_NUMBER_INVALID, MessageSeverity.error);
      return;
    }

    this.initialActionDate = this.auditService.timeAudit();
    if (!this.userId) {
      dataUser.createdBy = sessionStorage.getItem(AppConstants.Session.USERID)!;

      this.userService.saveRol(dataUser).subscribe({
        next: (req) => {
          this.loading = false;
          this.saveAuditAction();
          this.alertService.showMessage(AppConstants.TitleModal.ADD_USER, AppConstants.Messages.SUCCESS_REGISTER, MessageSeverity.success);
          this.back();
        }, error: (err) => {
          this.loading = false;
        }
      });
    } else {
      dataUser.id = this.userId;
      dataUser.updatedBy = sessionStorage.getItem(AppConstants.Session.USERID)!;
      this.userService.updateRol(dataUser).subscribe({
        next: (req) => {
          this.loading = false;
          this.saveAuditAction();
          this.alertService.showMessage(AppConstants.TitleModal.UPDATE_USER, AppConstants.Messages.SUCCESS_EDIT, MessageSeverity.success);
          this.back();
        }, error: (err) => {
          this.loading = false;
        }
      });
    }
  }

  openModal(row: any = 0) {
    this.loading = true;
    this.flag = "1";
    this.userRolForm.get('applicationId')?.enable();
    if (row != 0) {
      this.setFieldsRol(row);
      this.userRolForm.get('applicationId')?.disable();
      this.loading = false;
    } else {
      this.loading = false;
      this.clearFieldsRol();
    }
    this.userModal.show();
  }

  closeModal() {
    this.userModal.hide();
    this.clearFieldsRol();
  }

  setFieldsRol(req: any = 0) {
    this.userRolForm.patchValue(req);
    this.changeApplication();
    this.changeChannel();
  }

  setProfileChange(id: any) {
    this.loading = true;
    this.profileService.getProfileApplication(id).subscribe({
      next: (req) => {
        this.listProfiles = req;
        this.loading = false;
      }, error: (err) => {
        this.loading = false
      }
    });
  }

  clearFieldsRol() {
    this.userRolForm.controls['id'].setValue('');
    this.userRolForm.controls['applicationId'].setValue('');
    this.userRolForm.controls['profileId'].setValue('');
    this.userRolForm.controls['agencyId'].setValue('');
    this.userRolForm.controls['channelId'].setValue('');
    this.userRolForm.controls['subChannelId'].setValue('');
    this.userRolForm.controls['areaId'].setValue('');
    this.userRolForm.controls['state'].setValue(true);
  }

  saveRol() {
    if (this.userRolForm.valid) {
      const data = this.userRolForm.getRawValue();
      let profiles = this.userForm.controls['profiles'].value;
      if (!profiles) profiles = [];

      if (profiles.filter((x: { id: any; applicationId: any; profileId: any; }) => x.id != data.id &&
        x.applicationId == data.applicationId &&
        x.profileId == data.profileId).length > 0) {
        this.alertService.showMessage(AppConstants.TitleModal.ADD_USER, "El usuario ya cuenta con este rol asignado", MessageSeverity.error);
        return;
      }
      if (data.id) {
        let updateItem = profiles.find((x: { id: any; }) => x.id === data.id);
        let index = profiles.indexOf(updateItem);
        profiles[index] = data;
        data.profile = this.listProfiles.filter((x: any) => x.id == data.profileId)[0].name;
      } else {
        data.application = this.listApplications.filter((x: any) => x.id == data.applicationId)[0].name;
        data.profile = this.listProfiles.filter((x: any) => x.id == data.profileId)[0].name;
        data.id = Math.min(profiles.filter((x: { id: number; }) => x.id < 0).map((x: { id: any; }) => x.id)) - 1;
        profiles.push(data);
      }
      this.userForm.controls['profiles'].setValue(profiles);
      this.tableListUser.rows = this.perfiles;
      this.closeModal();
    }
  }

  userRolEvaluateError(formControlName: string) {
    return this.evaluateError(this.userRolForm, formControlName)
  }

  openModalAdditionalAttribute(row: any = 0): void {
    this.loading = true;
    if (row != 0) {
      this.setFieldsAdditionalAttribute(row);
      this.loading = false;
    } else {
      this.loading = false;
      this.clearFieldsAdditionalAttribute();
    }
    this.userAdditionalAttributeModal.show();

  }
  closeModalAdditionalAttribute() {
    this.userAdditionalAttributeModal.hide();
    this.clearFieldsAdditionalAttribute();
  }
  setFieldsAdditionalAttribute(req: any = 0) {
    this.userAdditionalAttributeForm.patchValue(req);
  }

  clearFieldsAdditionalAttribute() {
    this.userAdditionalAttributeForm.controls['id'].setValue('');
    this.userAdditionalAttributeForm.controls['userId'].setValue('');
    this.userAdditionalAttributeForm.controls['key'].setValue('');
    this.userAdditionalAttributeForm.controls['value'].setValue('');
    this.userAdditionalAttributeForm.controls['state'].setValue(true);
  }

  saveAdditionalAttribute(): void {
    if (this.userAdditionalAttributeForm.valid) {
      const data = this.userAdditionalAttributeForm.getRawValue();

      let additionalAttributes = this.userForm.controls['additionalAttributes'].value;
      if (!additionalAttributes) additionalAttributes = [];

      if (additionalAttributes.filter((x: { id: any; key: any; }) => x.id != data.id && x.key == data.key).length > 0) {
        this.alertService.showMessage(AppConstants.TitleModal.ADD_USER, "El usuario ya cuenta con este attributo", MessageSeverity.error);
        return;
      }

      if (data.id) {
        let updateItem = additionalAttributes.find((x: { id: any; }) => x.id === data.id);
        let index = additionalAttributes.indexOf(updateItem);
        additionalAttributes[index] = data;
      } else {
        additionalAttributes.push(data);
      }
      this.userForm.controls['additionalAttributes'].setValue(additionalAttributes);
      this.tableAdditionalAttribute.rows = additionalAttributes;
      this.closeModalAdditionalAttribute();
    }
  }

  userAdditionalAttributeEvaluateError(formControlName: string) {
    return this.evaluateError(this.userAdditionalAttributeForm, formControlName)
  }

  back() {
    this.location.back();
  }

  showMessage() {
    showMessage();
  }

  hideMessage() {
    hideMessage();
  }


}
