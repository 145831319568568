import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Tree } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile.service';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AuditService } from 'src/app/shared/services/audit.service';

@Component({
  selector: 'app-profile-action',
  templateUrl: './profile-action.component.html',
  styleUrls: ['../profile.component.css'],
})
export class ProfileActionComponent implements OnInit {

  // DATATABLE
  tableClass: string = 'table-height-1';
  loadingIndicator: boolean = false;
  rows = [] as any[];
  columns = [] as any[];

  // LABELS
  agencyCode: string = 'PE01254878';
  agencyName: string = 'Plaza Norte';
  usersessionId: string = 'jreyes';
  channelName: string = 'otros';

  loading: boolean = false;
  @ViewChild('actionModal') actionModal: ModalDirective;
  @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;
  @ViewChild('tableProfile') tableProfile: DatatableComponent;

  // MODAL
  titleModal: string = 'Acción';
  actionId: number = 0;
  actionApplication: string;
  actionProfile: string;

  // AUDIT
  initialActionDate: string;

   // PERMISSIONS
   menuActions: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.MENU_ACTIONS)!);
   canGet: boolean = false;
   canAdd: boolean = false;

  // TREE
  treeControl = new NestedTreeControl<Tree>(node => node.options);
  dataSource = new MatTreeNestedDataSource<Tree>();
  hasChild = (_: number, node: Tree) => !!node.options && node.options.length > 0;
  checklistSelection = new SelectionModel<Tree>(true);

  constructor(
    private profileService: ProfileService,
    private auditService: AuditService,
    private alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.onPermissions();
    this.checklistSelection = new SelectionModel<Tree>(true);
    this.checklistSelection.isSelected = (value): boolean => {
      return this.checklistSelection.selected.filter(x => x.id === value.id).length > 0;
    };
    this.createColumsTable();
  }

  // PERMISSIONS
  onPermissions() {
    this.menuActions.forEach((e) => {
      switch (e) {
        case AppConstants.MenuActions.GET_ACTION:
          this.canGet = true;
          break;
        case AppConstants.MenuActions.ADD_ACTION:
          this.canAdd = true;
          break;
      }
    })
  }

  // DATATABLE
  private createColumsTable(): void {
    this.columns = [
      { prop: 'name', name: 'Nombre', width: 50, draggable: false, resizeable: false, cellClass: 'text-center' },
      { cellTemplate: this.actionTemplate, name: 'Acciones', width: 50, draggable: false, resizeable: false, cellClass: 'text-center' },
    ];
  }

  // Tree
  onNode(node: Tree): void {
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.deselect(node);
      this.toggleChilds(node, false);
      node.state = false;
    } else {
      this.checklistSelection.select(node);
      this.toggleChilds(node, true);
      node.state = true;
    }
  }
  toggleChilds(node: Tree, selected: boolean): void {
    if (node.options && node.options.length > 0) {
      for (const child of node.options) {
        if (selected) {
          if (!this.checklistSelection.isSelected(child)) {
            this.checklistSelection.select(child);
          }
          child.state = true;
        } else {
          this.checklistSelection.deselect(child);
          child.state = false;
        }
        this.toggleChilds(child, selected);
      }
    }
  }
  loadSelections(node: Tree) {
    if (node.state) this.checklistSelection.select(node);
    if (node.options && node.options.length > 0) {
      for (const child of node.options) {
        this.loadSelections(child);
      }
    }
  }

  // ACTIONS
  getActions() {
    if (!this.canGet) return //NOT ALLOWED
    this.loading = true;
    this.profileService.getProfileActions(this.actionId).subscribe({
      next: (req) => {
        this.loading = false;
        this.dataSource.data = req;
        req.forEach((e: any) => { this.loadSelections(e) })
        this.tableProfile.rows = [];
        // console.log(req);
      }, error: (err) => {
        this.dataSource.data = [];
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.GET_ACTION, AppConstants.Messages.NO_DATA, MessageSeverity.error);
      }
    });
  }
  onActions(node: any) {
    this.tableProfile.rows = node.actions;
  }
  saveActions() {
    this.loading = true;
    var model = {
      id: this.actionId,
      updatedBy: sessionStorage.getItem(AppConstants.Session.USERID)!,
      menus: this.dataSource.data
    };
    this.initialActionDate = this.auditService.timeAudit();
    //NOT ALLOWED
    if (!this.canAdd) {
      this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ACTION, AppConstants.Messages.NOT_ALLOWED, MessageSeverity.info);
      this.loading = false; return;
    }
    this.profileService.saveActions(model).subscribe({
      next: (req) => {
        var todo = this.dataSource.data;
        this.loading = false;
        this.closeModal();
        this.saveAuditAction();
        this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ACTION, AppConstants.Messages.SUCCESS_DATA, MessageSeverity.success);
      }, error: (err) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleModal.UPDATE_ACTION, AppConstants.Messages.ERROR_OCURRED, MessageSeverity.error);
      }
    });
  }

  onActivate(event: any) {
    if (event.type == 'click') {
      event.row.state = !event.row.state;
    }
  }

  // AUDIT
  saveAuditAction() {
    var data = {
      auditId: +sessionStorage.getItem(AppConstants.Session.AUDIT)!,
      action: AppConstants.Actions.UPDATE,
      auditAction: AppConstants.TitleModal.UPDATE_ACTION,
      initialActionDate: this.initialActionDate,
      endActionDate: this.auditService.timeAudit()
    };
    this.auditService.saveAuditAction(data).subscribe((resp: any) => console.log(resp));
  }

  // MODAL
  openModal(row: any): void {
    this.actionId = row.id;
    this.actionApplication = row.application;
    this.actionProfile = row.name;
    this.ngOnInit();
    this.getActions();
    this.actionModal.show();
  }

  closeModal() {
    this.actionModal.hide();
  }

}
