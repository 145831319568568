<div class="wrap-view">
  <div class="row m-0" style="margin-top:-3%;">
    <div class="col-12 d-none d-md-block">
      <div class="row">
        <div class="col-md-4" style="padding:0">
          <img src="/assets/images/Chispas 1.png"/>
        </div>
        <div class="col-md-4" style="height:20px;"></div>
        <div class="col-md-4">
          <img src="/assets/images/Chispas 2.png"/>
        </div>
      </div>
    </div>
  </div>
  <div class="company-title">
    <div class="title-maestro">
      <span>Reporte</span>
    </div>
    <div class="filter-normal">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
              <label>Empresa</label>
              <select class="form-control input-form" [(ngModel)]="empresaId">
                <option selected [value]="0">Seleccione</option>
                <option *ngFor="let item of listEmpresa" value="{{item.id}}">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
              <label>Aplicación</label>
              <select class="form-control input-form" [(ngModel)]="applicationId">
                <option selected [value]="0">Seleccione</option>
                <option *ngFor="let item of listApplications" value="{{item.id}}">{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
              <label>Tipo de Usuario</label>
              <select class="form-control input-form" [(ngModel)]="userTypeId">
                <option selected [value]="0">Seleccione</option>
                <option *ngFor="let item of listUserType" value="{{item.key}}">{{item.value}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3">
              <label>Estado Usuario</label>
              <select class="form-control input-form" [(ngModel)]="userStateId">
                <option selected [value]="0">Seleccione</option>
                <option *ngFor="let item of listUserStates" value="{{item.id}}">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-md-5 col-lg-4 col-xl-3 d-none d-md-block">
              <button type="button" class="button-general inline-btn" title="Buscar" (click)="getReport()">Exportar
                Reporte
              </button>
            </div>
          </div>
        </div>
        <div class="d-block d-md-none w-100 mt-3">
          <button type="button" class="button-general inline-btn" title="Buscar"
                  (click)="getReport()">Exportar Reporte
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>

